import { createMuiTheme } from "@material-ui/core/styles";

import {
  srcRoleIconDeveloper,
  srcRoleIconLender,
  srcRoleIconGovernment,
  srcRoleIconDeveloperSelected,
  srcRoleIconLenderSelected,
  srcRoleIconGovernmentSelected,
} from "@/assets";

export const DEFAULT_THEME = createMuiTheme({
  palette: {
    primary: {
      light: "#FE138A",
      main: "#FE138A",
      dark: "#FE138A",
      contrastText: "#fff",
    },
  },
});

const { REACT_APP_GOOGLE_MAPS_API_KEY: GOOGLE_MAPS_API_KEY } = process.env;
export const GOOGLE_MAP_URL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=drawing`;

export const SWIPE_CONFIG = {
  delta: 50, // min distance(px) before a swipe starts
  preventDefaultTouchmoveEvent: false, // preventDefault on touchmove, *See Details*
  trackMouse: true, // track mouse input
};

export const LIST_MAX_RESULTS = 20;
export const DEVELOPMENTS_SETUP_MAX_PAGES = 6;
export const HARDWARES_SETUP_MAX_PAGES = 4;
export const REQUEST_QUOTE_MAX_PAGES = 6;
export const DEVELOPMENTS_MENU_WIDTH = "275px";
export const DEVELOPMENTS_MENU_HALF_WIDTH = "75px";
export const DEVELOPMENTS_TOOLBAR_HEIGHT = "70px";
export const MENU_ANIMATION_DURATION = "0.22s";

export const REG_ABSOLUTE_URL = /^(?:\w+:)\/\//;
export const REG_EMAIL =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+(?:[A-Z]{2}|com|org|net|edu|gov|mil|biz|info|name|media|aero|asia|cat|coop|int|jobs|mobi|museum|post|tel|travel|xxx)\b/i;
export const REG_AADHAR_NUMBER = /^[0-9]{4}\/?[0-9]{4}\/?[0-9]{4}$/;
export const REG_PHONE_NUMBER = /^([0-9]){10}$/;
export const REG_INDIAN_PHONE_NUMBER = /^(\+91)([0-9]){10}$/;
export const REG_PAN_NUMBER = /^([a-zA-Z0-9]){10}?$/;
export const REG_LOAN_ID_NUMBER = /^([0-9]){7}$/;
export const REG_BANK_ACCOUNT_NUMBER = /^\d{9,18}$/;
export const REG_IFSC_CODE = /^[a-zA-Z]{4}[0][a-zA-Z0-9]{6}$/;
export const REG_URN = /^[0-9]{4}\/?[0-9]{5}\/?[0-9]{5}$/;
export const REG_UTR =
  /^[0-9A-Z]{4}A[0-1][0-9][0-9]{3}[0-9]{6}$|^[0-9A-Z]{4}RC(?:19|20)[0-9]{2}[01][0-9][0-3][0-9][0-9]{8}$/;

export const MOCK_STATUS_WIDGET = [
  {
    name: "Total Remaining Funding",
    totalAmount: { currency: "KES", amount: 59000000 },
    color: "linear-gradient(225deg, #00BCB4 0%, #00BCB4 100%)",
  },
  {
    name: "Total Spent to Date",
    totalAmount: { currency: "KES", amount: 19000000 },
    color: "linear-gradient(225deg, #FE138A 0%, #FE138A 100%)",
  },
  {
    name: "Overall Budget",
    totalAmount: { currency: "KES", amount: 10000000 },
    color: "linear-gradient(225deg, #c41230 0%, #c41230 100%)",
  },
];

export const ACCOUNT_STATUS = Object.freeze({
  Active: "Active",
  Not_Activated: "Not Activated",
  Pending_Review: "Pending Review",
  Stub: "Stub",
});

export const MILESTONE_STATUS = Object.freeze({
  Approved: 0,
  Rejected: 1,
  Pending_Review: 2,
});

export const PROGRESS_MILESTONE_STATUS = Object.freeze({
  Completed: 0,
  Not_Complete: 1,
});

export const BLOCK_STATUS = Object.freeze({
  Finishings: 0,
  Site_Prep: 1,
  Construction_In_Progress: 2,
  Permit_Issued: 3,
  Not_Started: 4,
  Construction_Completed: 5,
  Occupancy: 6,
});

export const BLOCK_COLORS = [
  "#00BCB4",
  "#00BCB4",
  "#00BCB4",
  "#A19D9D",
  "#A19D9D",
  "#4AF56C",
  "#FE138A",
];

export const MILESTONE_COLORS = {
  Completed: "#4AF56C",
  "Pending Validation": "#00BCB4",
  Ongoing: "#FE138A",
  active: "#FE138A",
  "Not Started": "#A19D9D",
  approved: "#FE138A",
  closed: "#4FC44F",
  "Pending Review": "#00BCB4",
  "Pending Delivery": "#FE138A",
  "Pending Certification": "#FE138A",
  "Pending Loan Review": "#00BCB4",
  complete: "#4FC44F",
};

export const PAYMENT_STATUS = Object.freeze({
  Paid: 0,
  Rejected: 1,
  Pending: 2,
});
export const REQUEST_PAYMENT_STATUS = Object.freeze({
  Paid: "paid",
  Rejected: "rejected",
  Pending: "pending",
});

export const USER_TYPE = Object.freeze({
  Contractor: 0,
  Customer: 1,
});

export const ACCOUNT_STATUS_LIST = [
  {
    id: "ibuild_account_active",
    name: "Active",
  },
  {
    id: "ibuild_account_not_activated",
    name: "Not Activated",
  },
  {
    id: "loan_request",
    name: "Pending Review",
  },
  {
    id: "stub",
    name: "Stub",
  },
];
export const LOAN_STATUS_FILTER_LIST = [
  {
    id: "ibuild_account_active",
    name: "Active",
  },
  {
    id: "ibuild_account_not_activated",
    name: "Not Activated",
  },
  {
    id: "stub,loan_request",
    name: "Pending Review",
  },
];
export const APPROVAL_REQUEST_TYPE = [
  { id: 1, type: "Milestone" },
  { id: 2, type: "Supply Order" },
];

export const CATEGORY_LIST = [
  {
    id: "avatar",
    name: "Avatar",
    type: "image",
  },
  {
    id: "featured_image",
    name: "Featured Image",
    type: "image",
  },
  {
    id: "project_image",
    name: "Project Image",
    type: "image",
  },
  {
    id: "public",
    name: "Public",
    type: "image",
  },
  {
    id: "developer_module_image",
    name: "Developer Module Image",
    type: "image",
  },
  {
    id: "profile_document",
    name: "Profile Document",
    type: "doc",
  },
  {
    id: "project_document",
    name: "Project Document",
    type: "doc",
  },
  // {
  //   id: 'bill_of_quantities',
  //   name: 'Bill of Quantities',
  //   type: 'doc'
  // },
  {
    id: "drawings",
    name: "Drawings",
    type: "doc",
  },
  // {
  //   id: 'topographical_survey',
  //   name: 'Topographical Survey',
  //   type: 'doc'
  // },
  // {
  //   id: 'legal_documents',
  //   name: 'Legal Documents',
  //   type: 'doc'
  // },
  // {
  //   id: 'status_update',
  //   name: 'Status Update',
  //   type: 'doc'
  // },
  // {
  //   id: 'contractor_quote',
  //   name: 'Contractor Quote',
  //   type: 'doc'
  // },
  // {
  //   id: 'supplier_quote',
  //   name: 'Supplier Quote',
  //   type: 'doc'
  // },
  // {
  //   id: 'supplier_invoice',
  //   name: 'Supplier Invoice',
  //   type: 'doc'
  // },
  // {
  //   id: 'contractor_invoice',
  //   name: 'Contractor Invoice',
  //   type: 'doc'
  // },
  // {
  //   id: 'program_of_works',
  //   name: 'Program of Works',
  //   type: 'doc'
  // },
  // {
  //   id: 'references',
  //   name: 'References',
  //   type: 'doc'
  // },
  // {
  //   id: 'testimonials',
  //   name: 'Testimonials',
  //   type: 'doc'
  // }
];
export const REGIONS = [
  {
    id: 1,
    name: "Maharashtra",
  },
  {
    id: 2,
    name: "Uttar Pradesh",
  },
  {
    id: 3,
    name: "Gujarat",
  },
];
export const REJECTION_REASON_LIST = [
  {
    id: "incorrect_loan_id",
    name: "Incorrect Loan ID",
  },
  {
    id: "invalid_details",
    name: "Invalid Details",
  },
  {
    id: "credit_rejection",
    name: "Credit Rejection",
  },
  {
    id: "other",
    name: "Other",
  },
];

const SUPPORTED_COUNTRIES = new Set(["KE", "NG", "IN", "ID"]);
const SUPPORTED_LANGUAGES = new Set(["en", "hi"]);
const SUPPORTED_CURRENCIES = new Set(["USD", "INR", "IDR", "KES", "NGN"]);

export const ISO3166_1_COUNTRY_CODES = [
  { code: "AF", name: "Afghanistan" },
  { code: "AX", name: "\u00c5land Islands" },
  { code: "AL", name: "Albania" },
  { code: "DZ", name: "Algeria" },
  { code: "AS", name: "American Samoa" },
  { code: "AD", name: "Andorra" },
  { code: "AO", name: "Angola" },
  { code: "AI", name: "Anguilla" },
  { code: "AQ", name: "Antarctica" },
  { code: "AG", name: "Antigua and Barbuda" },
  { code: "AR", name: "Argentina" },
  { code: "AM", name: "Armenia" },
  { code: "AW", name: "Aruba" },
  { code: "AU", name: "Australia" },
  { code: "AT", name: "Austria" },
  { code: "AZ", name: "Azerbaijan" },
  { code: "BS", name: "Bahamas" },
  { code: "BH", name: "Bahrain" },
  { code: "BD", name: "Bangladesh" },
  { code: "BB", name: "Barbados" },
  { code: "BY", name: "Belarus" },
  { code: "BE", name: "Belgium" },
  { code: "BZ", name: "Belize" },
  { code: "BJ", name: "Benin" },
  { code: "BM", name: "Bermuda" },
  { code: "BT", name: "Bhutan" },
  { code: "BO", name: "Bolivia, Plurinational State of" },
  { code: "BQ", name: "Bonaire, Sint Eustatius and Saba" },
  { code: "BA", name: "Bosnia and Herzegovina" },
  { code: "BW", name: "Botswana" },
  { code: "BV", name: "Bouvet Island" },
  { code: "BR", name: "Brazil" },
  { code: "IO", name: "British Indian Ocean Territory" },
  { code: "BN", name: "Brunei Darussalam" },
  { code: "BG", name: "Bulgaria" },
  { code: "BF", name: "Burkina Faso" },
  { code: "BI", name: "Burundi" },
  { code: "KH", name: "Cambodia" },
  { code: "CM", name: "Cameroon" },
  { code: "CA", name: "Canada" },
  { code: "CV", name: "Cape Verde" },
  { code: "KY", name: "Cayman Islands" },
  { code: "CF", name: "Central African Republic" },
  { code: "TD", name: "Chad" },
  { code: "CL", name: "Chile" },
  { code: "CN", name: "China" },
  { code: "CX", name: "Christmas Island" },
  { code: "CC", name: "Cocos (Keeling) Islands" },
  { code: "CO", name: "Colombia" },
  { code: "KM", name: "Comoros" },
  { code: "CG", name: "Congo" },
  { code: "CD", name: "Congo, the Democratic Republic of the" },
  { code: "CK", name: "Cook Islands" },
  { code: "CR", name: "Costa Rica" },
  { code: "CI", name: "C\u00f4te d'Ivoire" },
  { code: "HR", name: "Croatia" },
  { code: "CU", name: "Cuba" },
  { code: "CW", name: "Cura\u00e7ao" },
  { code: "CY", name: "Cyprus" },
  { code: "CZ", name: "Czech Republic" },
  { code: "DK", name: "Denmark" },
  { code: "DJ", name: "Djibouti" },
  { code: "DM", name: "Dominica" },
  { code: "DO", name: "Dominican Republic" },
  { code: "EC", name: "Ecuador" },
  { code: "EG", name: "Egypt" },
  { code: "SV", name: "El Salvador" },
  { code: "GQ", name: "Equatorial Guinea" },
  { code: "ER", name: "Eritrea" },
  { code: "EE", name: "Estonia" },
  { code: "ET", name: "Ethiopia" },
  { code: "FK", name: "Falkland Islands (Malvinas)" },
  { code: "FO", name: "Faroe Islands" },
  { code: "FJ", name: "Fiji" },
  { code: "FI", name: "Finland" },
  { code: "FR", name: "France" },
  { code: "GF", name: "French Guiana" },
  { code: "PF", name: "French Polynesia" },
  { code: "TF", name: "French Southern Territories" },
  { code: "GA", name: "Gabon" },
  { code: "GM", name: "Gambia" },
  { code: "GE", name: "Georgia" },
  { code: "DE", name: "Germany" },
  { code: "GH", name: "Ghana" },
  { code: "GI", name: "Gibraltar" },
  { code: "GR", name: "Greece" },
  { code: "GL", name: "Greenland" },
  { code: "GD", name: "Grenada" },
  { code: "GP", name: "Guadeloupe" },
  { code: "GU", name: "Guam" },
  { code: "GT", name: "Guatemala" },
  { code: "GG", name: "Guernsey" },
  { code: "GN", name: "Guinea" },
  { code: "GW", name: "Guinea-Bissau" },
  { code: "GY", name: "Guyana" },
  { code: "HT", name: "Haiti" },
  { code: "HM", name: "Heard Island and McDonald Islands" },
  { code: "VA", name: "Holy See (Vatican City State)" },
  { code: "HN", name: "Honduras" },
  { code: "HK", name: "Hong Kong" },
  { code: "HU", name: "Hungary" },
  { code: "IS", name: "Iceland" },
  { code: "IN", name: "India" },
  { code: "ID", name: "Indonesia" },
  { code: "IR", name: "Iran, Islamic Republic of" },
  { code: "IQ", name: "Iraq" },
  { code: "IE", name: "Ireland" },
  { code: "IM", name: "Isle of Man" },
  { code: "IL", name: "Israel" },
  { code: "IT", name: "Italy" },
  { code: "JM", name: "Jamaica" },
  { code: "JP", name: "Japan" },
  { code: "JE", name: "Jersey" },
  { code: "JO", name: "Jordan" },
  { code: "KZ", name: "Kazakhstan" },
  { code: "KE", name: "Kenya" },
  { code: "KI", name: "Kiribati" },
  { code: "KP", name: "Korea, Democratic People's Republic of" },
  { code: "KR", name: "Korea, Republic of" },
  { code: "KW", name: "Kuwait" },
  { code: "KG", name: "Kyrgyzstan" },
  { code: "LA", name: "Lao People's Democratic Republic" },
  { code: "LV", name: "Latvia" },
  { code: "LB", name: "Lebanon" },
  { code: "LS", name: "Lesotho" },
  { code: "LR", name: "Liberia" },
  { code: "LY", name: "Libya" },
  { code: "LI", name: "Liechtenstein" },
  { code: "LT", name: "Lithuania" },
  { code: "LU", name: "Luxembourg" },
  { code: "MO", name: "Macao" },
  { code: "MK", name: "Macedonia, the Former Yugoslav Republic of" },
  { code: "MG", name: "Madagascar" },
  { code: "MW", name: "Malawi" },
  { code: "MY", name: "Malaysia" },
  { code: "MV", name: "Maldives" },
  { code: "ML", name: "Mali" },
  { code: "MT", name: "Malta" },
  { code: "MH", name: "Marshall Islands" },
  { code: "MQ", name: "Martinique" },
  { code: "MR", name: "Mauritania" },
  { code: "MU", name: "Mauritius" },
  { code: "YT", name: "Mayotte" },
  { code: "MX", name: "Mexico" },
  { code: "FM", name: "Micronesia, Federated States of" },
  { code: "MD", name: "Moldova, Republic of" },
  { code: "MC", name: "Monaco" },
  { code: "MN", name: "Mongolia" },
  { code: "ME", name: "Montenegro" },
  { code: "MS", name: "Montserrat" },
  { code: "MA", name: "Morocco" },
  { code: "MZ", name: "Mozambique" },
  { code: "MM", name: "Myanmar" },
  { code: "NA", name: "Namibia" },
  { code: "NR", name: "Nauru" },
  { code: "NP", name: "Nepal" },
  { code: "NL", name: "Netherlands" },
  { code: "NC", name: "New Caledonia" },
  { code: "NZ", name: "New Zealand" },
  { code: "NI", name: "Nicaragua" },
  { code: "NE", name: "Niger" },
  { code: "NG", name: "Nigeria" },
  { code: "NU", name: "Niue" },
  { code: "NF", name: "Norfolk Island" },
  { code: "MP", name: "Northern Mariana Islands" },
  { code: "NO", name: "Norway" },
  { code: "OM", name: "Oman" },
  { code: "PK", name: "Pakistan" },
  { code: "PW", name: "Palau" },
  { code: "PS", name: "Palestine, State of" },
  { code: "PA", name: "Panama" },
  { code: "PG", name: "Papua New Guinea" },
  { code: "PY", name: "Paraguay" },
  { code: "PE", name: "Peru" },
  { code: "PH", name: "Philippines" },
  { code: "PN", name: "Pitcairn" },
  { code: "PL", name: "Poland" },
  { code: "PT", name: "Portugal" },
  { code: "PR", name: "Puerto Rico" },
  { code: "QA", name: "Qatar" },
  { code: "RE", name: "R\u00e9union" },
  { code: "RO", name: "Romania" },
  { code: "RU", name: "Russian Federation" },
  { code: "RW", name: "Rwanda" },
  { code: "BL", name: "Saint Barth\u00e9lemy" },
  { code: "SH", name: "Saint Helena, Ascension and Tristan da Cunha" },
  { code: "KN", name: "Saint Kitts and Nevis" },
  { code: "LC", name: "Saint Lucia" },
  { code: "MF", name: "Saint Martin (French part)" },
  { code: "PM", name: "Saint Pierre and Miquelon" },
  { code: "VC", name: "Saint Vincent and the Grenadines" },
  { code: "WS", name: "Samoa" },
  { code: "SM", name: "San Marino" },
  { code: "ST", name: "Sao Tome and Principe" },
  { code: "SA", name: "Saudi Arabia" },
  { code: "SN", name: "Senegal" },
  { code: "RS", name: "Serbia" },
  { code: "SC", name: "Seychelles" },
  { code: "SL", name: "Sierra Leone" },
  { code: "SG", name: "Singapore" },
  { code: "SX", name: "Sint Maarten (Dutch part)" },
  { code: "SK", name: "Slovakia" },
  { code: "SI", name: "Slovenia" },
  { code: "SB", name: "Solomon Islands" },
  { code: "SO", name: "Somalia" },
  { code: "ZA", name: "South Africa" },
  { code: "GS", name: "South Georgia and the South Sandwich Islands" },
  { code: "SS", name: "South Sudan" },
  { code: "ES", name: "Spain" },
  { code: "LK", name: "Sri Lanka" },
  { code: "SD", name: "Sudan" },
  { code: "SR", name: "Suriname" },
  { code: "SJ", name: "Svalbard and Jan Mayen" },
  { code: "SZ", name: "Swaziland" },
  { code: "SE", name: "Sweden" },
  { code: "CH", name: "Switzerland" },
  { code: "SY", name: "Syrian Arab Republic" },
  { code: "TW", name: "Taiwan, Province of China" },
  { code: "TJ", name: "Tajikistan" },
  { code: "TZ", name: "Tanzania, United Republic of" },
  { code: "TH", name: "Thailand" },
  { code: "TL", name: "Timor-Leste" },
  { code: "TG", name: "Togo" },
  { code: "TK", name: "Tokelau" },
  { code: "TO", name: "Tonga" },
  { code: "TT", name: "Trinidad and Tobago" },
  { code: "TN", name: "Tunisia" },
  { code: "TR", name: "Turkey" },
  { code: "TM", name: "Turkmenistan" },
  { code: "TC", name: "Turks and Caicos Islands" },
  { code: "TV", name: "Tuvalu" },
  { code: "UG", name: "Uganda" },
  { code: "UA", name: "Ukraine" },
  { code: "AE", name: "United Arab Emirates" },
  { code: "GB", name: "United Kingdom" },
  { code: "US", name: "United States" },
  { code: "UM", name: "United States Minor Outlying Islands" },
  { code: "UY", name: "Uruguay" },
  { code: "UZ", name: "Uzbekistan" },
  { code: "VU", name: "Vanuatu" },
  { code: "VE", name: "Venezuela, Bolivarian Republic of" },
  { code: "VN", name: "Viet Nam" },
  { code: "VG", name: "Virgin Islands, British" },
  { code: "VI", name: "Virgin Islands, U.S." },
  { code: "WF", name: "Wallis and Futuna" },
  { code: "EH", name: "Western Sahara" },
  { code: "YE", name: "Yemen" },
  { code: "ZM", name: "Zambia" },
  { code: "ZW", name: "Zimbabwe" },
];

const ISO_639_2_LANGUAGE_CODES = [
  { englishName: "Afar", code: "aa" },
  { englishName: "Abkhazian", code: "ab" },
  { englishName: "Avestan", code: "ae" },
  { englishName: "Afrikaans", code: "af" },
  { englishName: "Akan", code: "ak" },
  { englishName: "Amharic", code: "am" },
  { englishName: "Aragonese", code: "an" },
  { englishName: "Arabic", code: "ar" },
  { englishName: "Assamese", code: "as" },
  { englishName: "Avaric", code: "av" },
  { englishName: "Aymara", code: "ay" },
  { englishName: "Azerbaijani", code: "az" },
  { englishName: "Bashkir", code: "ba" },
  { englishName: "Belarusian", code: "be" },
  { englishName: "Bulgarian", code: "bg" },
  { englishName: "Bihari languages", code: "bh" },
  { englishName: "Bislama", code: "bi" },
  { englishName: "Bambara", code: "bm" },
  { englishName: "Bengali", code: "bn" },
  { englishName: "Tibetan", code: "bo" },
  { englishName: "Breton", code: "br" },
  { englishName: "Bosnian", code: "bs" },
  { englishName: "Catalan; Valencian", code: "ca" },
  { englishName: "Chechen", code: "ce" },
  { englishName: "Chamorro", code: "ch" },
  { englishName: "Corsican", code: "co" },
  { englishName: "Cree", code: "cr" },
  { englishName: "Czech", code: "cs" },
  {
    englishName:
      "Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic",
    code: "cu",
  },
  { englishName: "Chuvash", code: "cv" },
  { englishName: "Welsh", code: "cy" },
  { englishName: "Danish", code: "da" },
  { englishName: "German", code: "de" },
  { englishName: "Divehi; Dhivehi; Maldivian", code: "dv" },
  { englishName: "Dzongkha", code: "dz" },
  { englishName: "Ewe", code: "ee" },
  { englishName: "Greek, Modern (1453-)", code: "el" },
  { englishName: "English", code: "en" },
  { englishName: "Esperanto", code: "eo" },
  { englishName: "Spanish; Castilian", code: "es" },
  { englishName: "Estonian", code: "et" },
  { englishName: "Basque", code: "eu" },
  { englishName: "Persian", code: "fa" },
  { englishName: "Fulah", code: "ff" },
  { englishName: "Finnish", code: "fi" },
  { englishName: "Fijian", code: "fj" },
  { englishName: "Faroese", code: "fo" },
  { englishName: "French", code: "fr" },
  { englishName: "Western Frisian", code: "fy" },
  { englishName: "Irish", code: "ga" },
  { englishName: "Gaelic; Scottish Gaelic", code: "gd" },
  { englishName: "Galician", code: "gl" },
  { englishName: "Guarani", code: "gn" },
  { englishName: "Gujarati", code: "gu" },
  { englishName: "Manx", code: "gv" },
  { englishName: "Hausa", code: "ha" },
  { englishName: "Hebrew", code: "he" },
  { englishName: "Hindi", code: "hi" },
  { englishName: "Hiri Motu", code: "ho" },
  { englishName: "Croatian", code: "hr" },
  { englishName: "Haitian; Haitian Creole", code: "ht" },
  { englishName: "Hungarian", code: "hu" },
  { englishName: "Armenian", code: "hy" },
  { englishName: "Herero", code: "hz" },
  {
    englishName: "Interlingua (International Auxiliary Language Association)",
    code: "ia",
  },
  { englishName: "Indonesian", code: "id" },
  { englishName: "Interlingue; Occidental", code: "ie" },
  { englishName: "Igbo", code: "ig" },
  { englishName: "Sichuan Yi; Nuosu", code: "ii" },
  { englishName: "Inupiaq", code: "ik" },
  { englishName: "Ido", code: "io" },
  { englishName: "Icelandic", code: "is" },
  { englishName: "Italian", code: "it" },
  { englishName: "Inuktitut", code: "iu" },
  { englishName: "Japanese", code: "ja" },
  { englishName: "Javanese", code: "jv" },
  { englishName: "Georgian", code: "ka" },
  { englishName: "Kongo", code: "kg" },
  { englishName: "Kikuyu; Gikuyu", code: "ki" },
  { englishName: "Kuanyama; Kwanyama", code: "kj" },
  { englishName: "Kazakh", code: "kk" },
  { englishName: "Kalaallisut; Greenlandic", code: "kl" },
  { englishName: "Central Khmer", code: "km" },
  { englishName: "Kannada", code: "kn" },
  { englishName: "Korean", code: "ko" },
  { englishName: "Kanuri", code: "kr" },
  { englishName: "Kashmiri", code: "ks" },
  { englishName: "Kurdish", code: "ku" },
  { englishName: "Komi", code: "kv" },
  { englishName: "Cornish", code: "kw" },
  { englishName: "Kirghiz; Kyrgyz", code: "ky" },
  { englishName: "Latin", code: "la" },
  { englishName: "Luxembourgish; Letzeburgesch", code: "lb" },
  { englishName: "Ganda", code: "lg" },
  { englishName: "Limburgan; Limburger; Limburgish", code: "li" },
  { englishName: "Lingala", code: "ln" },
  { englishName: "Lao", code: "lo" },
  { englishName: "Lithuanian", code: "lt" },
  { englishName: "Luba-Katanga", code: "lu" },
  { englishName: "Latvian", code: "lv" },
  { englishName: "Malagasy", code: "mg" },
  { englishName: "Marshallese", code: "mh" },
  { englishName: "Maori", code: "mi" },
  { englishName: "Macedonian", code: "mk" },
  { englishName: "Malayalam", code: "ml" },
  { englishName: "Mongolian", code: "mn" },
  { englishName: "Marathi", code: "mr" },
  { englishName: "Malay", code: "ms" },
  { englishName: "Maltese", code: "mt" },
  { englishName: "Burmese", code: "my" },
  { englishName: "Nauru", code: "na" },
  { englishName: "Bokm\u00e5l, Norwegian; Norwegian Bokm\u00e5l", code: "nb" },
  { englishName: "Ndebele, North; North Ndebele", code: "nd" },
  { englishName: "Nepali", code: "ne" },
  { englishName: "Ndonga", code: "ng" },
  { englishName: "Dutch; Flemish", code: "nl" },
  { englishName: "Norwegian Nynorsk; Nynorsk, Norwegian", code: "nn" },
  { englishName: "Norwegian", code: "no" },
  { englishName: "Ndebele, South; South Ndebele", code: "nr" },
  { englishName: "Navajo; Navaho", code: "nv" },
  { englishName: "Chichewa; Chewa; Nyanja", code: "ny" },
  { englishName: "Occitan (post 1500); Proven\u00e7al", code: "oc" },
  { englishName: "Ojibwa", code: "oj" },
  { englishName: "Oromo", code: "om" },
  { englishName: "Oriya", code: "or" },
  { englishName: "Ossetian; Ossetic", code: "os" },
  { englishName: "Panjabi; Punjabi", code: "pa" },
  { englishName: "Pali", code: "pi" },
  { englishName: "Polish", code: "pl" },
  { englishName: "Pushto; Pashto", code: "ps" },
  { englishName: "Portuguese", code: "pt" },
  { englishName: "Quechua", code: "qu" },
  { englishName: "Romansh", code: "rm" },
  { englishName: "Rundi", code: "rn" },
  { englishName: "Romanian; Moldavian; Moldovan", code: "ro" },
  { englishName: "Russian", code: "ru" },
  { englishName: "Kinyarwanda", code: "rw" },
  { englishName: "Sanskrit", code: "sa" },
  { englishName: "Sardinian", code: "sc" },
  { englishName: "Sindhi", code: "sd" },
  { englishName: "Northern Sami", code: "se" },
  { englishName: "Sango", code: "sg" },
  { englishName: "Sinhala; Sinhalese", code: "si" },
  { englishName: "Slovak", code: "sk" },
  { englishName: "Slovenian", code: "sl" },
  { englishName: "Samoan", code: "sm" },
  { englishName: "Shona", code: "sn" },
  { englishName: "Somali", code: "so" },
  { englishName: "Albanian", code: "sq" },
  { englishName: "Serbian", code: "sr" },
  { englishName: "Swati", code: "ss" },
  { englishName: "Sotho, Southern", code: "st" },
  { englishName: "Sundanese", code: "su" },
  { englishName: "Swedish", code: "sv" },
  { englishName: "Swahili", code: "sw" },
  { englishName: "Tamil", code: "ta" },
  { englishName: "Telugu", code: "te" },
  { englishName: "Tajik", code: "tg" },
  { englishName: "Thai", code: "th" },
  { englishName: "Tigrinya", code: "ti" },
  { englishName: "Turkmen", code: "tk" },
  { englishName: "Tagalog", code: "tl" },
  { englishName: "Tswana", code: "tn" },
  { englishName: "Tonga (Tonga Islands)", code: "to" },
  { englishName: "Turkish", code: "tr" },
  { englishName: "Tsonga", code: "ts" },
  { englishName: "Tatar", code: "tt" },
  { englishName: "Twi", code: "tw" },
  { englishName: "Tahitian", code: "ty" },
  { englishName: "Uighur; Uyghur", code: "ug" },
  { englishName: "Ukrainian", code: "uk" },
  { englishName: "Urdu", code: "ur" },
  { englishName: "Uzbek", code: "uz" },
  { englishName: "Venda", code: "ve" },
  { englishName: "Vietnamese", code: "vi" },
  { englishName: "Volap\u00fck", code: "vo" },
  { englishName: "Walloon", code: "wa" },
  { englishName: "Wolof", code: "wo" },
  { englishName: "Xhosa", code: "xh" },
  { englishName: "Yiddish", code: "yi" },
  { englishName: "Yoruba", code: "yo" },
  { englishName: "Zhuang; Chuang", code: "za" },
  { englishName: "Chinese", code: "zh" },
  { englishName: "Zulu", code: "zu" },
];

export const CURRENCY_CODES = [
  {
    symbol: "$",
    name: "US Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "USD",
    name_plural: "US dollars",
  },
  {
    symbol: "CA$",
    name: "Canadian Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "CAD",
    name_plural: "Canadian dollars",
  },
  {
    symbol: "€",
    name: "Euro",
    symbol_native: "€",
    decimal_digits: 2,
    rounding: 0,
    code: "EUR",
    name_plural: "euros",
  },
  {
    symbol: "AED",
    name: "United Arab Emirates Dirham",
    symbol_native: "د.إ.‏",
    decimal_digits: 2,
    rounding: 0,
    code: "AED",
    name_plural: "UAE dirhams",
  },
  {
    symbol: "Af",
    name: "Afghan Afghani",
    symbol_native: "؋",
    decimal_digits: 0,
    rounding: 0,
    code: "AFN",
    name_plural: "Afghan Afghanis",
  },
  {
    symbol: "ALL",
    name: "Albanian Lek",
    symbol_native: "Lek",
    decimal_digits: 0,
    rounding: 0,
    code: "ALL",
    name_plural: "Albanian lekë",
  },
  {
    symbol: "AMD",
    name: "Armenian Dram",
    symbol_native: "դր.",
    decimal_digits: 0,
    rounding: 0,
    code: "AMD",
    name_plural: "Armenian drams",
  },
  {
    symbol: "AR$",
    name: "Argentine Peso",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "ARS",
    name_plural: "Argentine pesos",
  },
  {
    symbol: "AU$",
    name: "Australian Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "AUD",
    name_plural: "Australian dollars",
  },
  {
    symbol: "man.",
    name: "Azerbaijani Manat",
    symbol_native: "ман.",
    decimal_digits: 2,
    rounding: 0,
    code: "AZN",
    name_plural: "Azerbaijani manats",
  },
  {
    symbol: "KM",
    name: "Bosnia-Herzegovina Convertible Mark",
    symbol_native: "KM",
    decimal_digits: 2,
    rounding: 0,
    code: "BAM",
    name_plural: "Bosnia-Herzegovina convertible marks",
  },
  {
    symbol: "Tk",
    name: "Bangladeshi Taka",
    symbol_native: "৳",
    decimal_digits: 2,
    rounding: 0,
    code: "BDT",
    name_plural: "Bangladeshi takas",
  },
  {
    symbol: "BGN",
    name: "Bulgarian Lev",
    symbol_native: "лв.",
    decimal_digits: 2,
    rounding: 0,
    code: "BGN",
    name_plural: "Bulgarian leva",
  },
  {
    symbol: "BD",
    name: "Bahraini Dinar",
    symbol_native: "د.ب.‏",
    decimal_digits: 3,
    rounding: 0,
    code: "BHD",
    name_plural: "Bahraini dinars",
  },
  {
    symbol: "FBu",
    name: "Burundian Franc",
    symbol_native: "FBu",
    decimal_digits: 0,
    rounding: 0,
    code: "BIF",
    name_plural: "Burundian francs",
  },
  {
    symbol: "BN$",
    name: "Brunei Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "BND",
    name_plural: "Brunei dollars",
  },
  {
    symbol: "Bs",
    name: "Bolivian Boliviano",
    symbol_native: "Bs",
    decimal_digits: 2,
    rounding: 0,
    code: "BOB",
    name_plural: "Bolivian bolivianos",
  },
  {
    symbol: "R$",
    name: "Brazilian Real",
    symbol_native: "R$",
    decimal_digits: 2,
    rounding: 0,
    code: "BRL",
    name_plural: "Brazilian reals",
  },
  {
    symbol: "BWP",
    name: "Botswanan Pula",
    symbol_native: "P",
    decimal_digits: 2,
    rounding: 0,
    code: "BWP",
    name_plural: "Botswanan pulas",
  },
  {
    symbol: "BYR",
    name: "Belarusian Ruble",
    symbol_native: "BYR",
    decimal_digits: 0,
    rounding: 0,
    code: "BYR",
    name_plural: "Belarusian rubles",
  },
  {
    symbol: "BZ$",
    name: "Belize Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "BZD",
    name_plural: "Belize dollars",
  },
  {
    symbol: "CDF",
    name: "Congolese Franc",
    symbol_native: "FrCD",
    decimal_digits: 2,
    rounding: 0,
    code: "CDF",
    name_plural: "Congolese francs",
  },
  {
    symbol: "CHF",
    name: "Swiss Franc",
    symbol_native: "CHF",
    decimal_digits: 2,
    rounding: 0.05,
    code: "CHF",
    name_plural: "Swiss francs",
  },
  {
    symbol: "CL$",
    name: "Chilean Peso",
    symbol_native: "$",
    decimal_digits: 0,
    rounding: 0,
    code: "CLP",
    name_plural: "Chilean pesos",
  },
  {
    symbol: "CN¥",
    name: "Chinese Yuan",
    symbol_native: "CN¥",
    decimal_digits: 2,
    rounding: 0,
    code: "CNY",
    name_plural: "Chinese yuan",
  },
  {
    symbol: "CO$",
    name: "Colombian Peso",
    symbol_native: "$",
    decimal_digits: 0,
    rounding: 0,
    code: "COP",
    name_plural: "Colombian pesos",
  },
  {
    symbol: "₡",
    name: "Costa Rican Colón",
    symbol_native: "₡",
    decimal_digits: 0,
    rounding: 0,
    code: "CRC",
    name_plural: "Costa Rican colóns",
  },
  {
    symbol: "CV$",
    name: "Cape Verdean Escudo",
    symbol_native: "CV$",
    decimal_digits: 2,
    rounding: 0,
    code: "CVE",
    name_plural: "Cape Verdean escudos",
  },
  {
    symbol: "Kč",
    name: "Czech Republic Koruna",
    symbol_native: "Kč",
    decimal_digits: 2,
    rounding: 0,
    code: "CZK",
    name_plural: "Czech Republic korunas",
  },
  {
    symbol: "Fdj",
    name: "Djiboutian Franc",
    symbol_native: "Fdj",
    decimal_digits: 0,
    rounding: 0,
    code: "DJF",
    name_plural: "Djiboutian francs",
  },
  {
    symbol: "Dkr",
    name: "Danish Krone",
    symbol_native: "kr",
    decimal_digits: 2,
    rounding: 0,
    code: "DKK",
    name_plural: "Danish kroner",
  },
  {
    symbol: "RD$",
    name: "Dominican Peso",
    symbol_native: "RD$",
    decimal_digits: 2,
    rounding: 0,
    code: "DOP",
    name_plural: "Dominican pesos",
  },
  {
    symbol: "DA",
    name: "Algerian Dinar",
    symbol_native: "د.ج.‏",
    decimal_digits: 2,
    rounding: 0,
    code: "DZD",
    name_plural: "Algerian dinars",
  },
  {
    symbol: "Ekr",
    name: "Estonian Kroon",
    symbol_native: "kr",
    decimal_digits: 2,
    rounding: 0,
    code: "EEK",
    name_plural: "Estonian kroons",
  },
  {
    symbol: "EGP",
    name: "Egyptian Pound",
    symbol_native: "ج.م.‏",
    decimal_digits: 2,
    rounding: 0,
    code: "EGP",
    name_plural: "Egyptian pounds",
  },
  {
    symbol: "Nfk",
    name: "Eritrean Nakfa",
    symbol_native: "Nfk",
    decimal_digits: 2,
    rounding: 0,
    code: "ERN",
    name_plural: "Eritrean nakfas",
  },
  {
    symbol: "Br",
    name: "Ethiopian Birr",
    symbol_native: "Br",
    decimal_digits: 2,
    rounding: 0,
    code: "ETB",
    name_plural: "Ethiopian birrs",
  },
  {
    symbol: "£",
    name: "British Pound Sterling",
    symbol_native: "£",
    decimal_digits: 2,
    rounding: 0,
    code: "GBP",
    name_plural: "British pounds sterling",
  },
  {
    symbol: "GEL",
    name: "Georgian Lari",
    symbol_native: "GEL",
    decimal_digits: 2,
    rounding: 0,
    code: "GEL",
    name_plural: "Georgian laris",
  },
  {
    symbol: "GH₵",
    name: "Ghanaian Cedi",
    symbol_native: "GH₵",
    decimal_digits: 2,
    rounding: 0,
    code: "GHS",
    name_plural: "Ghanaian cedis",
  },
  {
    symbol: "FG",
    name: "Guinean Franc",
    symbol_native: "FG",
    decimal_digits: 0,
    rounding: 0,
    code: "GNF",
    name_plural: "Guinean francs",
  },
  {
    symbol: "GTQ",
    name: "Guatemalan Quetzal",
    symbol_native: "Q",
    decimal_digits: 2,
    rounding: 0,
    code: "GTQ",
    name_plural: "Guatemalan quetzals",
  },
  {
    symbol: "HK$",
    name: "Hong Kong Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "HKD",
    name_plural: "Hong Kong dollars",
  },
  {
    symbol: "HNL",
    name: "Honduran Lempira",
    symbol_native: "L",
    decimal_digits: 2,
    rounding: 0,
    code: "HNL",
    name_plural: "Honduran lempiras",
  },
  {
    symbol: "kn",
    name: "Croatian Kuna",
    symbol_native: "kn",
    decimal_digits: 2,
    rounding: 0,
    code: "HRK",
    name_plural: "Croatian kunas",
  },
  {
    symbol: "Ft",
    name: "Hungarian Forint",
    symbol_native: "Ft",
    decimal_digits: 0,
    rounding: 0,
    code: "HUF",
    name_plural: "Hungarian forints",
  },
  {
    symbol: "Rp",
    name: "Indonesian Rupiah",
    symbol_native: "Rp",
    decimal_digits: 0,
    rounding: 0,
    code: "IDR",
    name_plural: "Indonesian rupiahs",
  },
  {
    symbol: "₪",
    name: "Israeli New Sheqel",
    symbol_native: "₪",
    decimal_digits: 2,
    rounding: 0,
    code: "ILS",
    name_plural: "Israeli new sheqels",
  },
  {
    symbol: "₹",
    name: "Indian Rupee",
    symbol_native: "টকা",
    decimal_digits: 2,
    rounding: 0,
    code: "INR",
    name_plural: "Indian rupees",
  },
  {
    symbol: "IQD",
    name: "Iraqi Dinar",
    symbol_native: "د.ع.‏",
    decimal_digits: 0,
    rounding: 0,
    code: "IQD",
    name_plural: "Iraqi dinars",
  },
  {
    symbol: "IRR",
    name: "Iranian Rial",
    symbol_native: "﷼",
    decimal_digits: 0,
    rounding: 0,
    code: "IRR",
    name_plural: "Iranian rials",
  },
  {
    symbol: "Ikr",
    name: "Icelandic Króna",
    symbol_native: "kr",
    decimal_digits: 0,
    rounding: 0,
    code: "ISK",
    name_plural: "Icelandic krónur",
  },
  {
    symbol: "J$",
    name: "Jamaican Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "JMD",
    name_plural: "Jamaican dollars",
  },
  {
    symbol: "JD",
    name: "Jordanian Dinar",
    symbol_native: "د.أ.‏",
    decimal_digits: 3,
    rounding: 0,
    code: "JOD",
    name_plural: "Jordanian dinars",
  },
  {
    symbol: "¥",
    name: "Japanese Yen",
    symbol_native: "￥",
    decimal_digits: 0,
    rounding: 0,
    code: "JPY",
    name_plural: "Japanese yen",
  },
  {
    symbol: "Ksh",
    name: "Kenyan Shilling",
    symbol_native: "Ksh",
    decimal_digits: 2,
    rounding: 0,
    code: "KES",
    name_plural: "Kenyan shillings",
  },
  {
    symbol: "KHR",
    name: "Cambodian Riel",
    symbol_native: "៛",
    decimal_digits: 2,
    rounding: 0,
    code: "KHR",
    name_plural: "Cambodian riels",
  },
  {
    symbol: "CF",
    name: "Comorian Franc",
    symbol_native: "FC",
    decimal_digits: 0,
    rounding: 0,
    code: "KMF",
    name_plural: "Comorian francs",
  },
  {
    symbol: "₩",
    name: "South Korean Won",
    symbol_native: "₩",
    decimal_digits: 0,
    rounding: 0,
    code: "KRW",
    name_plural: "South Korean won",
  },
  {
    symbol: "KD",
    name: "Kuwaiti Dinar",
    symbol_native: "د.ك.‏",
    decimal_digits: 3,
    rounding: 0,
    code: "KWD",
    name_plural: "Kuwaiti dinars",
  },
  {
    symbol: "KZT",
    name: "Kazakhstani Tenge",
    symbol_native: "тңг.",
    decimal_digits: 2,
    rounding: 0,
    code: "KZT",
    name_plural: "Kazakhstani tenges",
  },
  {
    symbol: "LB£",
    name: "Lebanese Pound",
    symbol_native: "ل.ل.‏",
    decimal_digits: 0,
    rounding: 0,
    code: "LBP",
    name_plural: "Lebanese pounds",
  },
  {
    symbol: "SLRs",
    name: "Sri Lankan Rupee",
    symbol_native: "SL Re",
    decimal_digits: 2,
    rounding: 0,
    code: "LKR",
    name_plural: "Sri Lankan rupees",
  },
  {
    symbol: "Lt",
    name: "Lithuanian Litas",
    symbol_native: "Lt",
    decimal_digits: 2,
    rounding: 0,
    code: "LTL",
    name_plural: "Lithuanian litai",
  },
  {
    symbol: "Ls",
    name: "Latvian Lats",
    symbol_native: "Ls",
    decimal_digits: 2,
    rounding: 0,
    code: "LVL",
    name_plural: "Latvian lati",
  },
  {
    symbol: "LD",
    name: "Libyan Dinar",
    symbol_native: "د.ل.‏",
    decimal_digits: 3,
    rounding: 0,
    code: "LYD",
    name_plural: "Libyan dinars",
  },
  {
    symbol: "MAD",
    name: "Moroccan Dirham",
    symbol_native: "د.م.‏",
    decimal_digits: 2,
    rounding: 0,
    code: "MAD",
    name_plural: "Moroccan dirhams",
  },
  {
    symbol: "MDL",
    name: "Moldovan Leu",
    symbol_native: "MDL",
    decimal_digits: 2,
    rounding: 0,
    code: "MDL",
    name_plural: "Moldovan lei",
  },
  {
    symbol: "MGA",
    name: "Malagasy Ariary",
    symbol_native: "MGA",
    decimal_digits: 0,
    rounding: 0,
    code: "MGA",
    name_plural: "Malagasy Ariaries",
  },
  {
    symbol: "MKD",
    name: "Macedonian Denar",
    symbol_native: "MKD",
    decimal_digits: 2,
    rounding: 0,
    code: "MKD",
    name_plural: "Macedonian denari",
  },
  {
    symbol: "MMK",
    name: "Myanma Kyat",
    symbol_native: "K",
    decimal_digits: 0,
    rounding: 0,
    code: "MMK",
    name_plural: "Myanma kyats",
  },
  {
    symbol: "MOP$",
    name: "Macanese Pataca",
    symbol_native: "MOP$",
    decimal_digits: 2,
    rounding: 0,
    code: "MOP",
    name_plural: "Macanese patacas",
  },
  {
    symbol: "MURs",
    name: "Mauritian Rupee",
    symbol_native: "MURs",
    decimal_digits: 0,
    rounding: 0,
    code: "MUR",
    name_plural: "Mauritian rupees",
  },
  {
    symbol: "MX$",
    name: "Mexican Peso",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "MXN",
    name_plural: "Mexican pesos",
  },
  {
    symbol: "RM",
    name: "Malaysian Ringgit",
    symbol_native: "RM",
    decimal_digits: 2,
    rounding: 0,
    code: "MYR",
    name_plural: "Malaysian ringgits",
  },
  {
    symbol: "MTn",
    name: "Mozambican Metical",
    symbol_native: "MTn",
    decimal_digits: 2,
    rounding: 0,
    code: "MZN",
    name_plural: "Mozambican meticals",
  },
  {
    symbol: "N$",
    name: "Namibian Dollar",
    symbol_native: "N$",
    decimal_digits: 2,
    rounding: 0,
    code: "NAD",
    name_plural: "Namibian dollars",
  },
  {
    symbol: "₦",
    name: "Nigerian Naira",
    symbol_native: "₦",
    decimal_digits: 2,
    rounding: 0,
    code: "NGN",
    name_plural: "Nigerian nairas",
  },
  {
    symbol: "C$",
    name: "Nicaraguan Córdoba",
    symbol_native: "C$",
    decimal_digits: 2,
    rounding: 0,
    code: "NIO",
    name_plural: "Nicaraguan córdobas",
  },
  {
    symbol: "Nkr",
    name: "Norwegian Krone",
    symbol_native: "kr",
    decimal_digits: 2,
    rounding: 0,
    code: "NOK",
    name_plural: "Norwegian kroner",
  },
  {
    symbol: "NPRs",
    name: "Nepalese Rupee",
    symbol_native: "नेरू",
    decimal_digits: 2,
    rounding: 0,
    code: "NPR",
    name_plural: "Nepalese rupees",
  },
  {
    symbol: "NZ$",
    name: "New Zealand Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "NZD",
    name_plural: "New Zealand dollars",
  },
  {
    symbol: "OMR",
    name: "Omani Rial",
    symbol_native: "ر.ع.‏",
    decimal_digits: 3,
    rounding: 0,
    code: "OMR",
    name_plural: "Omani rials",
  },
  {
    symbol: "B/.",
    name: "Panamanian Balboa",
    symbol_native: "B/.",
    decimal_digits: 2,
    rounding: 0,
    code: "PAB",
    name_plural: "Panamanian balboas",
  },
  {
    symbol: "S/.",
    name: "Peruvian Nuevo Sol",
    symbol_native: "S/.",
    decimal_digits: 2,
    rounding: 0,
    code: "PEN",
    name_plural: "Peruvian nuevos soles",
  },
  {
    symbol: "₱",
    name: "Philippine Peso",
    symbol_native: "₱",
    decimal_digits: 2,
    rounding: 0,
    code: "PHP",
    name_plural: "Philippine pesos",
  },
  {
    symbol: "PKRs",
    name: "Pakistani Rupee",
    symbol_native: "₨",
    decimal_digits: 0,
    rounding: 0,
    code: "PKR",
    name_plural: "Pakistani rupees",
  },
  {
    symbol: "zł",
    name: "Polish Zloty",
    symbol_native: "zł",
    decimal_digits: 2,
    rounding: 0,
    code: "PLN",
    name_plural: "Polish zlotys",
  },
  {
    symbol: "₲",
    name: "Paraguayan Guarani",
    symbol_native: "₲",
    decimal_digits: 0,
    rounding: 0,
    code: "PYG",
    name_plural: "Paraguayan guaranis",
  },
  {
    symbol: "QR",
    name: "Qatari Rial",
    symbol_native: "ر.ق.‏",
    decimal_digits: 2,
    rounding: 0,
    code: "QAR",
    name_plural: "Qatari rials",
  },
  {
    symbol: "RON",
    name: "Romanian Leu",
    symbol_native: "RON",
    decimal_digits: 2,
    rounding: 0,
    code: "RON",
    name_plural: "Romanian lei",
  },
  {
    symbol: "din.",
    name: "Serbian Dinar",
    symbol_native: "дин.",
    decimal_digits: 0,
    rounding: 0,
    code: "RSD",
    name_plural: "Serbian dinars",
  },
  {
    symbol: "RUB",
    name: "Russian Ruble",
    symbol_native: "руб.",
    decimal_digits: 2,
    rounding: 0,
    code: "RUB",
    name_plural: "Russian rubles",
  },
  {
    symbol: "RWF",
    name: "Rwandan Franc",
    symbol_native: "FR",
    decimal_digits: 0,
    rounding: 0,
    code: "RWF",
    name_plural: "Rwandan francs",
  },
  {
    symbol: "SR",
    name: "Saudi Riyal",
    symbol_native: "ر.س.‏",
    decimal_digits: 2,
    rounding: 0,
    code: "SAR",
    name_plural: "Saudi riyals",
  },
  {
    symbol: "SDG",
    name: "Sudanese Pound",
    symbol_native: "SDG",
    decimal_digits: 2,
    rounding: 0,
    code: "SDG",
    name_plural: "Sudanese pounds",
  },
  {
    symbol: "Skr",
    name: "Swedish Krona",
    symbol_native: "kr",
    decimal_digits: 2,
    rounding: 0,
    code: "SEK",
    name_plural: "Swedish kronor",
  },
  {
    symbol: "S$",
    name: "Singapore Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "SGD",
    name_plural: "Singapore dollars",
  },
  {
    symbol: "Ssh",
    name: "Somali Shilling",
    symbol_native: "Ssh",
    decimal_digits: 0,
    rounding: 0,
    code: "SOS",
    name_plural: "Somali shillings",
  },
  {
    symbol: "SY£",
    name: "Syrian Pound",
    symbol_native: "ل.س.‏",
    decimal_digits: 0,
    rounding: 0,
    code: "SYP",
    name_plural: "Syrian pounds",
  },
  {
    symbol: "฿",
    name: "Thai Baht",
    symbol_native: "฿",
    decimal_digits: 2,
    rounding: 0,
    code: "THB",
    name_plural: "Thai baht",
  },
  {
    symbol: "DT",
    name: "Tunisian Dinar",
    symbol_native: "د.ت.‏",
    decimal_digits: 3,
    rounding: 0,
    code: "TND",
    name_plural: "Tunisian dinars",
  },
  {
    symbol: "T$",
    name: "Tongan Paʻanga",
    symbol_native: "T$",
    decimal_digits: 2,
    rounding: 0,
    code: "TOP",
    name_plural: "Tongan paʻanga",
  },
  {
    symbol: "TL",
    name: "Turkish Lira",
    symbol_native: "TL",
    decimal_digits: 2,
    rounding: 0,
    code: "TRY",
    name_plural: "Turkish Lira",
  },
  {
    symbol: "TT$",
    name: "Trinidad and Tobago Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "TTD",
    name_plural: "Trinidad and Tobago dollars",
  },
  {
    symbol: "NT$",
    name: "New Taiwan Dollar",
    symbol_native: "NT$",
    decimal_digits: 2,
    rounding: 0,
    code: "TWD",
    name_plural: "New Taiwan dollars",
  },
  {
    symbol: "TSh",
    name: "Tanzanian Shilling",
    symbol_native: "TSh",
    decimal_digits: 0,
    rounding: 0,
    code: "TZS",
    name_plural: "Tanzanian shillings",
  },
  {
    symbol: "₴",
    name: "Ukrainian Hryvnia",
    symbol_native: "₴",
    decimal_digits: 2,
    rounding: 0,
    code: "UAH",
    name_plural: "Ukrainian hryvnias",
  },
  {
    symbol: "USh",
    name: "Ugandan Shilling",
    symbol_native: "USh",
    decimal_digits: 0,
    rounding: 0,
    code: "UGX",
    name_plural: "Ugandan shillings",
  },
  {
    symbol: "$U",
    name: "Uruguayan Peso",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "UYU",
    name_plural: "Uruguayan pesos",
  },
  {
    symbol: "UZS",
    name: "Uzbekistan Som",
    symbol_native: "UZS",
    decimal_digits: 0,
    rounding: 0,
    code: "UZS",
    name_plural: "Uzbekistan som",
  },
  {
    symbol: "Bs.F.",
    name: "Venezuelan Bolívar",
    symbol_native: "Bs.F.",
    decimal_digits: 2,
    rounding: 0,
    code: "VEF",
    name_plural: "Venezuelan bolívars",
  },
  {
    symbol: "₫",
    name: "Vietnamese Dong",
    symbol_native: "₫",
    decimal_digits: 0,
    rounding: 0,
    code: "VND",
    name_plural: "Vietnamese dong",
  },
  {
    symbol: "FCFA",
    name: "CFA Franc BEAC",
    symbol_native: "FCFA",
    decimal_digits: 0,
    rounding: 0,
    code: "XAF",
    name_plural: "CFA francs BEAC",
  },
  {
    symbol: "CFA",
    name: "CFA Franc BCEAO",
    symbol_native: "CFA",
    decimal_digits: 0,
    rounding: 0,
    code: "XOF",
    name_plural: "CFA francs BCEAO",
  },
  {
    symbol: "YR",
    name: "Yemeni Rial",
    symbol_native: "ر.ي.‏",
    decimal_digits: 0,
    rounding: 0,
    code: "YER",
    name_plural: "Yemeni rials",
  },
  {
    symbol: "R",
    name: "South African Rand",
    symbol_native: "R",
    decimal_digits: 2,
    rounding: 0,
    code: "ZAR",
    name_plural: "South African rand",
  },
  {
    symbol: "ZK",
    name: "Zambian Kwacha",
    symbol_native: "ZK",
    decimal_digits: 0,
    rounding: 0,
    code: "ZMK",
    name_plural: "Zambian kwachas",
  },
];

export const SUPPORTED_ISO3166_1_COUNTRY_CODES = ISO3166_1_COUNTRY_CODES.filter(
  (tuple) => SUPPORTED_COUNTRIES.has(tuple.code)
);
export const SUPPORTED_ISO_639_2_LANGUAGE_CODES =
  ISO_639_2_LANGUAGE_CODES.filter((tuple) =>
    SUPPORTED_LANGUAGES.has(tuple.code)
  );
export const SUPPORTED_CURRENCY_CODES = CURRENCY_CODES.filter((tuple) =>
  SUPPORTED_CURRENCIES.has(tuple.code)
);

export const ROLES = [
  {
    key: "developer",
    value: "Developer",
    img: srcRoleIconDeveloper,
    imgSelected: srcRoleIconDeveloperSelected,
  },
  {
    key: "lender",
    value: "Lender",
    img: srcRoleIconLender,
    imgSelected: srcRoleIconLenderSelected,
  },
  {
    key: "government",
    value: "Government",
    img: srcRoleIconGovernment,
    imgSelected: srcRoleIconGovernmentSelected,
  },
];

export const DOCUMENTS_TABLE_CONFIG = [
  {
    id: "name",
    numeric: false,
    label: "NAME",
    width: "30%",
  },
  {
    id: "file_type",
    numeric: true,
    label: "FILE TYPE",
    width: "10%",
  },
  {
    id: "category",
    numeric: true,
    label: "CATEGORY",
    width: "20%",
  },
  {
    id: "source",
    numeric: true,
    label: "SOURCE",
    width: "20%",
  },
  {
    id: "modified",
    numeric: true,
    label: "MODIFIED",
    width: "20%",
  },
];

export const CONTRACTORS_TABLE_CONFIG = [
  {
    id: "name",
    numeric: false,
    label: "Name",
    width: "25%",
  },
  {
    id: "file_type",
    numeric: true,
    label: "Rating",
    width: "25%",
  },
  {
    id: "category",
    numeric: true,
    label: "Category",
    width: "25%",
  },
  {
    id: "modified",
    numeric: true,
    label: "",
    width: "20%",
  },
];

// List of sorting criteria for the Sort By drop-down for loans
export const SORT_CRITERIA_LOANS = [
  { id: 1, criteria: "Customer_Name" },
  { id: 2, criteria: "Last_Updated" },
  { id: 3, criteria: "Loan_ID" },
  { id: 5, criteria: "Loans.Sanctioned_Amount" },
];

// List of sorting criteria for the Sort By drop-down for milestones
export const SORT_CRITERIA_MILESTONES = [
  { id: 1, criteria: "Customer_Name" },
  { id: 2, criteria: "Milestones.Contractor_Name" },
  { id: 3, criteria: "Last_Updated" },
  { id: 4, criteria: "Loan_ID" },
  { id: 5, criteria: "Milestones.Milestone_Amount" },
];

// List of sorting criteria for the Sort By drop-down for requests
export const SORT_CRITERIA_REQUESTS = [
  { id: 1, criteria: "Requests.Requestor_Name" },
  { id: 2, criteria: "Requests.Request_Date" },
  { id: 3, criteria: "Requests.User_ID" },
  { id: 4, criteria: "Requests.Withdraw_Amount" },
  { id: 5, criteria: "Requests.Status" },
];

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyDLIBuhCmbm8hshgtkO7hFvV296Ia1qzm0",
  authDomain: "fundi-mjanja-508e3.firebaseapp.com",
  databaseURL: "https://fundi-mjanja-508e3.firebaseio.com",
  projectId: "fundi-mjanja-508e3",
  storageBucket: "fundi-mjanja-508e3.appspot.com",
  messagingSenderId: "502582871864",
  appId: "1:502582871864:web:1d3e8edd95d9b1b6",
  measurementId: "G-QJFZXMQFQ1",
};
