/* eslint-disable no-unused-vars */
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import MiniDrawer from "./MiniDrawer";
import Services from "./material-orders/index";
import Header from "./Header";
import CnfidenceChat from "./CnfidenceChat";
function main() {
  return (
    <div className="main">
      <Header />
      <MiniDrawer />
      <Switch>
        <Route
          exact
          path="/"
          render={() => <Redirect to="/confidence/confidence-chat" />}
        />
        <Route path="/confidence/services" component={Services} />
        <Route path="/confidence/confidence-chat" component={CnfidenceChat} />
      </Switch>
    </div>
  );
}

export default main;
