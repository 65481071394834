/* eslint-disable no-unused-vars */

/* global google */
import React, { Component, useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import Marker from "./Marker";
import HomeImage from "./HomeImage";
var circle;
const Geomap = ({ project, images, radius, setCoordinates }) => {
  const [home, setHome] = useState(project);
  console.log(home);
  let { lat, lng } = home;
  useEffect(() => {
    setHome(project);
    swapradius(project);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radius, project, images]);

  const swapradius = (project) => {
    if (circle) {
      console.log(home);
      circle.setCenter({ lat: project.lat, lng: project.lng });
      circle.setRadius(radius);
    }
  };

  const apiIsLoaded = (map, maps) => {
    if (map) {
      const latLng = new google.maps.LatLng(lat, lng); // Makes a latlng
      map.panTo(latLng);
      circle = new google.maps.Circle({
        strokeColor: "#FE138A",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#DBE487",
        fillOpacity: 0.3,
        map,
        center: { lat, lng },
        radius,
      });
    }
  };
  const dropMarker = (t) => {
    const lat = t.lat;
    const lng = t.lng;

    setHome({ lat, lng });
    swapradius({ lat, lng });

    if (setCoordinates) {
      setCoordinates({ lat, lng });
    }
  };
  return (
    <div className="map-section">
      <div className="map" id="google-map">
        {project ? (
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyDLIBuhCmbm8hshgtkO7hFvV296Ia1qzm0",
              v: "3.31",
            }}
            defaultCenter={{ lat, lng }}
            defaultZoom={10}
            center={{ lat, lng }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
            onClick={dropMarker}>
            {images.map((image, index) => {
              return (
                <Marker
                  key={index}
                  lat={image.latitude}
                  lng={image.longitude}
                  name="My Marker"
                  color="blue"
                />
              );
            })}
            <HomeImage lat={home.lat} lng={home.lng} />
          </GoogleMapReact>
        ) : null}
      </div>
    </div>
  );
};

export default Geomap;
