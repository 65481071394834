import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import authReducer from "./authReducer";

const rootPersistConfig = {
  key: "iBuildGlobal",
  storage,
  // blacklist: ['milestonesReducer', 'loansReducer', 'requestsReducer', 'blocksReducer', 'developmentsReducer']
  whitelist: ["appReducer", "i18nReducer", "authReducer"],
  // whitelist: ['appReducer', 'i18nReducer']
};
const rootReducer = combineReducers({
  authReducer,
});
const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export default persistedReducer;
