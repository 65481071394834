import { takeLatest } from "redux-saga/effects";

import { userLogin, userSignup } from "./authSaga";

function* mySaga() {
  yield takeLatest("LOGIN_REQUEST", userLogin);
  yield takeLatest("USER_SIGN_UP", userSignup);
}

export default mySaga;
