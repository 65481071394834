import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
const initialState = {
  activeUser: null,
  error: null,
  toastId: null,
  phone_number: null,

  messos: [
    {
      isme: false,
      text: "Hi! My name is CONFIDENCE. What would you like to talk about today?",
      id: uuidv4(),
      date: Date.now(),
    },
  ],
  mymessos: [],
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    // User Login
    case "LOGIN_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
        toastId: toast("Logging in...", { autoClose: false }),
      };
    case "LOGIN_SUCCESS": {
      const { user, token } = action.payload;
      toast.update(state.toastId, {
        render: "Login Success",
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      });
      localStorage.setItem("iBG_Token", token);
      // localStorage.setItem('user', JSON.stringify(user))
      return {
        ...state,
        loading: false,
        error: null,
        activeUser: user,
      };
    }

    case "LOGIN_FAILURE":
      toast.update(state.toastId, {
        render: action.payload,
        type: toast.TYPE.ERROR,
        autoClose: 3000,
      });
      return {
        ...state,
        loading: false,
        error: action.payload,
        activeUser: null,
      };

    // User Signup
    case "USER_SIGN_UP":
      return {
        ...state,
        error: null,
        toastId: toast("Signing up...", { autoClose: false }),
      };
    case "UPDATE_CHATME":
      return {
        ...state,
        error: null,
        mymessos: action.payload,
      };
    case "UPDATE_CHATBOT":
      let bottexts = [...state.messos];
      bottexts.push(action.payload);
      return {
        ...state,
        error: null,
        messos: action.payload,
      };
    case "USER_SIGNUP_SUCCESS":
      const { user, token } = action.payload;
      toast.update(state.toastId, {
        render: "Signup Success",
        type: toast.TYPE.SUCCESS,
        autoClose: 3000,
      });
      localStorage.setItem("iBG_Token", token);
      return {
        ...state,
        error: null,
        activeUser: user,
      };
    case "SIGNUP_FAILURE":
      toast.update(state.toastId, {
        render: action.payload,
        type: toast.TYPE.ERROR,
        autoClose: 3000,
      });
      return {
        ...state,
        error: action.payload,
        activeUser: null,
      };
    case "LOGIN_FIREBASE_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
        toastId: toast("Verifying..."),
      };
    case "SET_PHONE":
      console.log(action.payload);
      return {
        ...state,
        phone_number: action.payload,
      };
    // User Logout
    case "LOGOUT_REQUEST":
      toast("Logout Success", { type: toast.TYPE.SUCCESS, autoClose: 3000 });
      localStorage.removeItem("iBG_Token");

      //   localStorage.removeItem('user')
      //   localStorage.removeItem('firetoken')
      //   localStorage.removeItem('registered')
      //   localStorage.removeItem('iBG_DefaultBusinessID')
      //   localStorage.removeItem('fire')
      return {
        ...initialState,
      };
    default:
      return state;
  }
}
