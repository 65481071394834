
import React from 'react'
import { withRouter } from 'react-router-dom'
import {
  ListItem,
  ListItemIcon,
  Avatar,
  Box
} from '@material-ui/core'

function Supplier({ supplier: { id, name, job }, history }) {
  const handleDetail = () => {
    history.push(`/developments/material-orders/${id}`)
  }

  return (
    <ListItem button onClick={handleDetail}>
      <ListItemIcon>
        <Avatar alt={name} src="https://public.ibuild.global/defaults/default_avatar.jpg" />
      </ListItemIcon>
      <Box display="flex" flexDirection="column">
        <Box mb={1} fontSize={12} fontWeight={600}>{name}</Box>
        <Box fontSize={11} fontWeight={300}>{job}</Box>
      </Box>
    </ListItem>
  )
}

export default withRouter(Supplier)
