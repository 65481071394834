import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { HashRouter } from "react-router-dom";
import { createHashHistory } from "history";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./services/serviceWorker";
import configureStore from "@/configure/configureStore";
import dotenv from "dotenv";
dotenv.config();
const history = createHashHistory();
const { Store, persistor } = configureStore(history);

ReactDOM.render(
  <Provider store={Store}>
    <PersistGate persistor={persistor}>
      <HashRouter>
        <App />
      </HashRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
