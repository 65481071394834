/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { TextField, InputAdornment, InputLabel } from "@material-ui/core";
import girl from "../assets/AYZH/ayzh_model.png";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PhoneIcon from "@material-ui/icons/Phone";
import logo from "../assets/AYZH/logo.png";
import OtpInput from "react-otp-input";
import { userLogin } from "@/actions";

export const SignIn = ({ userLogin, phone_number }) => {
  const [pin, onChangeText] = useState("");

  const onChange = (val) => {
    let data = {
      username: phone_number,
      password: pin,
    };
    userLogin(data);
  };
  return (
    <div className="signIn_details">
      <div className="white">
        <img src={girl} style={{ height: "300px" }} alt="girl" />
        <h1>CHAT WITH CONFIDENCE</h1>
      </div>
      <div className="scarlet">
        <div className="otp-input">
          <OtpInput
            value={pin}
            onChange={onChangeText}
            inputStyle={{
              width: "30px",
              height: "3rem",
              margin: "10px 5px",
              fontSize: "2rem",
              borderRadius: "4px",
              border: "2px solid #FFF",
            }}
            numInputs={4}
            isInputNum
            isInputSecure
          />
          <span className="call-to-action">Please enter your personal PIN</span>
        </div>

        <div
          className="signin-signup"
          style={{ justifyContent: "center" }}
          onClick={onChange}>
          <span>SUBMIT</span>
        </div>

        <img src={logo} alt="logo"></img>
      </div>
      <div className="orange"></div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  phone_number: state.authReducer.phone_number,
});
const mapDispatchToProps = (dispatch) => ({
  userLogin: (data) => dispatch(userLogin(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
