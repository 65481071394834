import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { routerMiddleware } from "react-router-redux";
import { persistStore } from "redux-persist";

import persistedReducer from "@/reducers";
import saga from "@/sagas";

const sagaMiddleware = createSagaMiddleware();
let Store = null;

export function getStore() {
  return Store;
}

export default function configureStore(history) {
  const reduxRouterMiddleware = routerMiddleware(history);
  const middleware = [sagaMiddleware, reduxRouterMiddleware];

  // if (process.env.NODE_ENV !== 'production') {
  //   middleware.push(createLogger())
  // }
  const { REACT_APP_ENDPOINT: iBG_Endpoint } = process.env;
  if (
    !localStorage.getItem("iBG_Endpoint") ||
    localStorage.getItem("iBG_Endpoint") !== process.env.REACT_APP_ENDPOINT
  ) {
    localStorage.setItem("iBG_Endpoint", iBG_Endpoint);
  }

  Store = createStore(
    persistedReducer,
    compose(
      applyMiddleware(...middleware),
      // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
      window.devToolsExtension
        ? window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true })
        : (f) => f
    )
  );
  const persistor = persistStore(Store);
  sagaMiddleware.run(saga);
  return { Store, persistor };
}
