import React from "react";
import { Switch, Route } from "react-router-dom";

import { withRouter } from "react-router";
import { compose } from "redux";
import { connect } from "react-redux";

import Main from "./components/main";
import PrivateRoute from "./authGuard";
import PhonePage from "./components/phonePage";
import Signup from "./components/signup";
import SignIn from "./components/SignIn";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const App = (props) => {
  const { activeUser } = props;
  return (
    <div className="App">
      <ToastContainer />
      <Switch>
        <Route path="/Login" component={withRouter(PhonePage)} />
        <Route path="/sign-up" component={withRouter(Signup)} />
        <Route path="/sign-in" component={withRouter(SignIn)} />

        <PrivateRoute
          activeUser={activeUser}
          path="/confidence"
          component={Main}
        />
        <PrivateRoute activeUser={activeUser} path="/" component={Main} />
      </Switch>
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeUser: state.authReducer.activeUser,
});

const withConnect = connect(mapStateToProps);

export default compose(withRouter, withConnect)(App);
