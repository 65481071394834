/* eslint-disable no-unused-vars */
import { put, delay, call, select } from "redux-saga/effects";
import { push } from "react-router-redux";
import { authApi } from "../services/api";
import { authReducer } from "./selectors";

// const commonRequests = function* commonRequests() {
//   yield put({ type:'GET_COUNTRIES_REQUEST' })
//   yield delay()
//   // yield put({ type: 'GET_REGIONS_REQUEST' })

//   // yield put({ type: 'GET_FIREBASE_TOKEN' })

//   yield put({ type: 'GET_SERVICES_REQUEST' })
//   yield delay()

//   yield put({ type: 'GET_CONTRACTORS_REQUEST' })

//   yield delay()

//   yield put({ type:'GET_CHECKLIST_REQUEST' })

//   yield delay()

//   yield put({ type: 'GET_SKILLS_REQUEST' })

//   yield delay()
// }

export const userLogin = function* userLogin(action) {
  try {
    const response = yield authApi.userLogin(action.payload);
    const { success, data, error, message } = response;
    if (success) {
      yield put({ type: "LOGIN_SUCCESS", payload: data });

      yield put(push("/confidence/confidence-chat"));
    } else {
      yield put({ type: "LOGIN_FAILURE", payload: error || "Unable to login" });
    }
  } catch (e) {
    console.log(
      "***---userLogin---***",
      `${Object.keys(e.response.data.errors).toString()}: ${Object.values(
        e.response.data.errors
      ).toString()}`
    );
    yield put({
      type: "LOGIN_FAILURE",
      payload:
        `${Object.keys(e.response.data.errors).toString()}: ${Object.values(
          e.response.data.errors
        ).toString()}` || "Unable to login",
    });
  }
};

export const userSignup = function* userSignup(action) {
  try {
    const response = yield authApi.userSignup(action.payload);
    const { success, data: user, error, message } = response;

    if (success) {
      // commonRequests()

      yield put({ type: "USER_SIGNUP_SUCCESS", payload: user });

      yield put(push("/confidence/confidence-chat"));
    } else {
      yield put({
        type: "USER_SIGNUP_FAILURE",
        payload: message || "Unable to login",
      });
    }
  } catch (e) {
    console.log(
      "***---userSignup---***",
      `${Object.keys(e.response.data.errors).toString()}: ${Object.values(
        e.response.data.errors
      ).toString()}`
    );
    yield put({
      type: "USER_SIGNUP_FAILURE",
      payload:
        `${Object.keys(e.response.data.errors).toString()}: ${Object.values(
          e.response.data.errors
        ).toString()}` || "unable to sign up",
    });
  }
};

export const getFirebaseToken = function* getFirebaseToken() {
  try {
    let code = null;
    const { activeUser } = yield select(authReducer);
  } catch (e) {
    console.log("***---FirebaseSignup---***", e);
    yield put({ type: "FIREBASE_SIGNUP_FAILURE", payload: "SHIT" });
  }
};
const gotoBIz = function* gotoBiz(idToken) {
  yield put(push("/business"));
  yield put({ type: "FIRE_LOGIN_SUCCESS", payload: idToken });
};

async function fire(code) {
  console.log(code);
  const confirmationResult = JSON.parse(localStorage.getItem("fireCode"));

  console.log(window.confirmationResult);
  const verificationId = code.payload;
  await window.confirmationResult
    .confirm(verificationId)
    .then(function (result) {
      // User signed in successfully.
      let user = result.user;
      user.getIdToken().then((idToken) => {
        console.log(idToken);
        localStorage.setItem("fireToken", idToken);
        return idToken;
      });
    })
    .catch(function (error) {
      // User couldn't sign in (bad verification code?)
      console.error("Error while checking the verification code", error);
    });
}
export const userLoginFire = function* userLoginFire(code) {
  console.log(code);
  try {
    const tokena = yield call(fire, code);
    yield gotoBIz(tokena);
  } catch (e) {
    console.log("***---FirebaseSignupToken---***", e);
    yield put({ type: "FIREBASE_SIGNUP_FAILURE", payload: "SHIT" });
  }
};
