/* eslint-disable no-unused-vars */
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Route } from 'react-router-dom'
import  Geomap from '../geomap'
import Pagination from '@material-ui/lab/Pagination'
import Typography from '@material-ui/core/Typography'
import Slider from '@material-ui/core/Slider'
import Popper from '@material-ui/core/Popper'
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete'
import Avatar from 'react-avatar';
import { toast } from 'react-toastify';
// import {getSupplierDetails, getHardwareMaterialOrders} from '@/actions'
// If you want to use the provided css
import 'react-google-places-autocomplete/dist/index.min.css'
import {
  Box,
  Card,
  List,
  Divider,
  IconButton, TextField,Dialog,DialogTitle
} from '@material-ui/core'
import {
  Navigation as NavigationIcon,
  Search as SearchIcon
} from '@material-ui/icons'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import Chip from '@material-ui/core/Chip'
import DoneIcon from '@material-ui/icons/Done'

import axios from 'axios'
import StarRatings from 'react-star-ratings'
import _ from 'lodash'
import SearchComponent from './SearchComponent'


export class Materials extends Component {
    state={
      allhardwares:[],
      user:this.props.user,
      hardwares:[],
      filters:[],
      open: false,
      provider:null,
      rating:0,
      page:1,
      radius:15000,
      productsearchterm: '',
      rating_modal:false,
      products:[],
      anchorEl: null,
      review_comment:'',
      // coordinates:null
      coordinates: {
         lat: 12.9716,
         lng:77.5946
      }
    }
    componentDidMount() {
      this.fetchhardwares()
    }

    changeRadius = (value) => {
      console.log('changing')
      let radius = value * 1000
      this.setState({
        radius
      }, () => this.fetchhardwares())
    }
    productsearch = (e) => {
      const endpoint = localStorage.getItem('iBG_Endpoint') ? localStorage.getItem('iBG_Endpoint') : process.env.REACT_APP_ENDPOINT
      let value = e.target.value
      this.setState({
        productsearchterm:value,
        open: value !== '',
        anchorEl:e.target
      })
      let config = {
        method: 'get',
        url:`${endpoint}api/services?search=${value}`,
        headers: { 
           'Authorization': `Bearer ${localStorage.getItem('iBG_Token')}`
        }
      }
      axios(config).then(res => {
        let products = res.data.data
        this.setState({
          products
        })
      })

    }
    fetchhardwares = () => {
      const endpoint = localStorage.getItem('iBG_Endpoint') ? localStorage.getItem('iBG_Endpoint') : process.env.REACT_APP_ENDPOINT
      const { user, coordinates } = this.state
      let self = this
      let filters = this.state.filters.map(item => {
        return item.name
      }).toString()
      // console.log(filters)
      let config = {
        method: 'get',
        url: filters.length ? `${endpoint}api/businesses?location[search_radius]=${this.state.radius}&location[lng]=${coordinates ? coordinates.lat : user.lat}&location[lat]=${coordinates ? coordinates.lng : user.lng}&services=${filters}` :
                              `${endpoint}api/businesses?location[search_radius]=${this.state.radius}&location[lng]=${coordinates ? coordinates.lat : user.lat}&location[lat]=${coordinates ? coordinates.lng : user.lng}`,
        headers: { 
           'Authorization': `Bearer ${localStorage.getItem('iBG_Token')}`
        }
      }

      axios(config)
        .then(function (response) {

          let page = 1
          let allhardwares = response.data.data
          const indexOfLastHardware = page * 10
          const indexOfFirstHardware = indexOfLastHardware - 10
          const currentHardwares = allhardwares.slice(indexOfFirstHardware, indexOfLastHardware)
          self.setState({
            allhardwares,
            hardwares:currentHardwares
          })
        })
        .catch(function (error) {
          console.log(error)
        })
    }

    handleChangePage = (e, p) => {

      // let page = p
      // let all_hard = this.state.allhardwares
      // const indexOfLastHardware = page * 10
      // const indexOfFirstHardware = indexOfLastHardware - 10
      // const currentHardwares = all_hard.slice(indexOfFirstHardware, indexOfLastHardware)
      // this.setState({
      //   hardwares:currentHardwares,
      //   page
      // })
    }
    filter =(type, value) => {
      const allhardwares = this.state.allhardwares
      let hardwares = null
      if(value) {
        if(type === 'location') {
          hardwares = _.filter(allhardwares, { location: value.location })
        }else if (type === 'name') {
          hardwares =  _.filter(allhardwares, { name: value.name })
        }

        this.setState({
          hardwares:hardwares.length ? hardwares : allhardwares
        })
      }else{
        this.handleChangePage('test', 1)
      }
    }
     valuetext =(value) => {
       return `${value}Km`
     }
     handleClose = () => {
       this.setState({
         open:false
       })
     }
     addfilter = (item) => {
       let filters = this.state.filters
       filters.push(item)
       this.setState({
         filters,
         productsearchterm:item.name,
         open:false
       }, () => this.fetchhardwares())
     }
     handleDelete = (item) => {
       let { filters } = this.state
       filters.splice(filters.findIndex(filter => filter.id === item.id), 1)
       this.setState({
         filters
       }, () => this.fetchhardwares())
     }
     fetchlatlng(desc) {
       geocodeByAddress(desc)
         .then(results => getLatLng(results[0]))
         .then(({ lat, lng }) =>
         //  console.log('Successfully got latitude and longitude', { lat, lng })
           this.setState({
             coordinates:{ lat, lng }
           }, () => this.fetchhardwares()))


     }
    //  setHardware = (hardware) => {
    //   const {history} = this.props
    //   this.props.getHardwareMaterialOrders(hardware.id)
    //   this.props.getSupplierDetails(hardware)
    //   history.push(`/supplier/orders/hardware/${hardware.id}`)
    //  }
  changeRating=( newRating, name ) =>{
      this.setState({
        rating: newRating
      });
  }
  openRating = (item) => {
    this.setState({
      rating_modal: true,
      provider:item
    })
  }
  handleRating = async() => {
    // this.setState({
    //   rating_modal:false
    // })
    toast.info("Posting review")
     let self = this
     const endpoint = localStorage.getItem('iBG_Endpoint') ? localStorage.getItem('iBG_Endpoint') : process.env.REACT_APP_ENDPOINT
     
      
      let config = {
        method: 'post',
        url: `${endpoint}api/businesses/${this.state.provider.id}/reviews?`,
         data: {
           five_point_rating: this.state.rating,
          //  reviewable_id:this.state.provider.id
        },
        headers: { 
           'Authorization': `Bearer ${localStorage.getItem('iBG_Token')}`
        }
        }
      

      axios(config).then(function (response) {

        toast.success("Review Posted")
       
          self.setState({
           rating_modal:false
          })
         self.fetchhardwares()
        })
        .catch(function (error) {
          console.log(error)
        })
  }
     render() {
       const  {
         hardwares, page, allhardwares,provider, radius, productsearchterm, filters, open, anchorEl, products, coordinates
       } = this.state
      //  const user = this.props.user
       const user = {
         lat:12.9716 ,
         lng:77.5946
       }
       const open_modal = this.state.rating_modal
       return (
         <Fragment>
           <Dialog onClose={() => {
             this.setState({
              rating_modal:false
           })
           }} aria-labelledby="simple-dialog-title" open={open_modal} >
             <div className="rate-provider">
              
               <div className="rating_description">
                 {provider ? <p>How would you rate {provider.name}?</p> : null}

                 
                  <StarRatings
          rating={this.state.rating}
         
          changeRating={this.changeRating}
          numberOfStars={5}
                 name='rating'
                 starRatedColor="#FE138A"
                 starHoverColor="#FE138A"
                         starDimension="25px"
                         starSpacing="5px"
                 />
                 {/* <TextField onChange={(e) => this.setState({
                 review_comment:e.target.value
                 })} style={{ margin: "10px", width: 300,textAlign:'center' }} placeholder="Write a review comment." /> */}
               </div>
         
               <div onClick={()=>this.handleRating()} className="submit">
                 Submit
               </div>
             </div>
    </Dialog>
           <Box m={0} display="flex">
             <div className="searchBox search-product">
               <TextField id="products-search" value={productsearchterm} label="Search Services" onChange={e => this.productsearch(e)} />

               {open ?
                 <div className="products">
                   {products.length ? products.map((item, index) => {
                     return (
                       <div className="product" key={index} onClick={e => this.addfilter(item)}>
                         <img src={require('@/assets/menu/icon_material_orders_colored.svg')} alt="product" />

                         <div className="supply-desc">
                           <h5>{item.name}</h5>
                           <span>{item.category}</span>
                         </div>

                       </div>
                     )
                   }) : 'Sorry no services found'}
                 </div> : null }

             </div>

             {hardwares && hardwares.length ?   <SearchComponent filter={this.filter} hardwares={allhardwares} type="name" label="Search Service Providers" /> : null}

             {/* <SearchComponent filter={this.filter} hardwares={allhardwares} type="location" label="Search location" /> */}
             <div className="searchBox search-product">
               <GooglePlacesAutocomplete
               placeholder="Enter Your location"
                 onSelect={({ description }) => this.fetchlatlng(description)}
               />
             </div>
             <div className="searchBox search-product">
               {filters.map((item, index) => {
                 return(<Chip
                   key={index}
                   label={item.name}
                   color="primary"
                   onDelete={e => this.handleDelete(item)}
                   deleteIcon={<DoneIcon />}
                 />)
               })}
             </div>
           </Box>
           <Box m={0} display="flex" >
             <Box width={1 / 2} mr={1}>
               {hardwares && hardwares.length ? hardwares.map((item, index) => {
                 return (
                   <div key={index} className="supplier">
                     {/* <img src={item.thumbnail_url ? item.thumbnail_url : require(`@/assets/defaults/${Math.floor((Math.random() * 7) + 1)}.png`)} alt="supplier"/> */}
                     <Avatar className="avatar" name={item.name} size='40px' />
                     <div className="supply-desc">
                       <h5>{item.name}</h5>
                       <span><LocationOnIcon color="primary" style={{ fontSize: 10 }}  />{item.physical_address}</span>
                       <span>{item.phone_no}</span>
                     </div>
                     <div className="ratings" onClick={()=>this.openRating(item)}>
                       <StarRatings
                         rating={item.average_rating ? parseFloat(item.average_rating) : 0}
                         starRatedColor="#FE138A"
                         starDimension="15px"
                         starSpacing="5px"
                       />
                       <span>Rating {item.average_rating ? parseFloat(item.average_rating).toFixed(1) : 'no rating yet'}</span>
                     </div>
                   </div>
                 )
               })  : null}
               {hardwares && hardwares.length ? <Pagination onChange={(e, p) => this.handleChangePage(e, p)} count={Math.ceil((allhardwares.length) / (10))} shape="rounded" page={page} siblingCount={0} boundaryCount={2}  /> : null }
             </Box>
             <Box width={1 / 2} mr={1} className="suppliers-map">
               <Typography id="discrete-slider" gutterBottom>
        Search radius Kms
               </Typography>
               <Slider
                 value={Math.floor(radius / 1000)}
                 getAriaValueText={this.valuetext}
                 aria-labelledby="discrete-slider"
                 valueLabelDisplay="auto"
                 onChangeCommitted={(e, val) => this.changeRadius(val)}
                 step={5}
                 marks
                 min={5}
                 max={500}
               />

               <Geomap radius={radius} images={hardwares.length ? hardwares : []} project={this.state.coordinates}/>
             </Box>
           </Box>
         </Fragment>
       )
     }
}

const mapStateToProps = state => ({
  user: state.authReducer.activeUser
})
const mapDispatchToProps = (dispatch) => ({
  // getSupplierDetails: (data) => dispatch(getSupplierDetails(data)),
  // getHardwareMaterialOrders:  (data) =>dispatch(getHardwareMaterialOrders(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Materials))
