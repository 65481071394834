import React from "react";
import { Route, Redirect } from "react-router-dom";

export default function PrivateRoute({
  component: Component,
  activeUser,
  ...rest
}) {
  const token = localStorage.getItem("iBG_Token");
  return (
    <Route
      {...rest}
      render={(props) =>
        token && activeUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
}
