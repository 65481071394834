/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { userLogout } from "@/actions";
import { withRouter } from "react-router";
import Popover from "@material-ui/core/Popover";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import logo from "../assets/AYZH/logo.png";
import Avatar from "react-avatar";

class Header extends Component {
  state = {
    anchorEl: null,
  };
  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };
  logOut = () => {
    const { userLogout } = this.props;
    userLogout();
  };
  render() {
    const { user } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    return (
      <header>
        <div className="logo">
          <img src={logo} alt="logo"></img>
          <h3 style={{ textAlign: "left", color: "#65509D", opacity: 1 }}>
            <span style={{ fontSize: "1.2em" }}>C</span>HAT{" "}
            <span style={{ fontSize: "1.2em" }}>W</span>ITH{" "}
            <span style={{ fontSize: "1.2em" }}>C</span>ONFIDENCE
          </h3>
        </div>

        <div className="language-select"></div>
        <div className="profile-section">
          <Avatar
            className="avatar"
            style={{
              borderRadius: "50%",
            }}
            name={user ? user.name : "AYZH"}
            size="40px"
            onClick={(e) => this.handleClick(e)}
          />
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={(e) => this.handleClose()}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}>
            <div
              className="logout-div"
              id="logout-button"
              onClick={this.logOut}>
              <PowerSettingsNewIcon></PowerSettingsNewIcon>
              <span>Logout</span>
            </div>
          </Popover>
        </div>
      </header>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.authReducer.activeUser,
});
const mapDispatchToProps = (dispatch) => ({
  userLogout: (data) => dispatch(userLogout()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Header);
