import React, { Component } from "react";
import mapIcon from "@/assets/svgs/map.svg";
import house from "@/assets/AYZH/services/hospital (1).svg";
export class Marker extends Component {
  render() {
    return (
      <div className="home-img">
        <img className="first-box" src={mapIcon} alt="flags" />
        <img src={house} alt="home" className="inner-box" />
      </div>
    );
  }
}

export default Marker;
