import React from "react";
import styled from "styled-components";
import Loading from "@/assets/images/loading3.gif";
// import { FormattedMessage } from 'react-intl'
import {
  Container,
  withStyles,
  Box,
  Fab,
  Dialog,
  DialogTitle,
  Checkbox,
  DialogContent,
  IconButton,
  Button,
  Tabs,
  Tab,
  Paper,
  List,
  CircularProgress,
  TextField,
  Avatar,
  Typography,
} from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { CircularProgressbar } from "react-circular-progressbar";
import srcBgMain from "@/assets/images/bg_main.jpg";

import {
  DEVELOPMENTS_TOOLBAR_HEIGHT,
  MENU_ANIMATION_DURATION,
} from "@/configure/constants";

export const ToolBar = styled(Box)`
  position: sticky;
  top: 64px;
  left: 0;
  right: 0;
  background: #ffffff;
  z-index: 1;
  padding: 0;
  padding-top: 2rem;
  padding-bottom: 4px;
`;

export const FilterBar = styled(Box)`
  display: flex;
`;

export const HeadingContainer = styled.div`
  margin-left: 20px;
  margin-bottom: 20px;
`;

export const ButtonContainer = styled.div`
  margin-left: 20px;
  margin-right: 20px;
`;

export const DropDownItem = styled.div`
  position: relative;
  margin-left: ${(props) => (props.noMargin ? 0 : 20)}px;
  flex: 1;
`;

export const LoanStatusWrapper = styled.div`
  width: 100%;
  height: 48px;
  line-height: 48px;
  min-width: 48px;
  min-height: auto;
  border-radius: 24px;
  border-color: ${(props) => props.backgroundcolor || "white"};
  border-width: 2px;
  border-style: solid;
  text-align: center;
`;

export const ProgressStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
`;

export const ListItemContainer = styled.div``;

// Custom dialog
const CustomDialogTitleStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    textAlign: "center",
    borderBottom: (props) => (props.border ? "1px solid gray" : "none"),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
export const CustomDialogTitle = withStyles(CustomDialogTitleStyles)(
  ({ children, classes, onClose }) => (
    <DialogTitle disableTypography className={classes.root}>
      {children}
      {onClose && (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      )}
    </DialogTitle>
  )
);
export const CustomDialogContent = withStyles({
  root: {
    position: "relative",
    display: (props) => props.display || "block",
    padding: 0,
    overflowY: "visible",
    minHeight: (props) => props.height || 300,
  },
})(DialogContent);
export const CustomDialog = withStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    overflowY: "visible",
    maxWidth: "none",
    minWidth: (props) => props.minwidth || 750,
  },
})(Dialog);
export const DialogActions = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2rem;

  button {
    font-size: 12px;
    text-transform: capitalize;
  }
`;

// Custom Tab
export const CustomTabs = withStyles({
  root: {
    backgroundColor: "#f4f4f4",
    width: "calc(100% + 10px)",
    margin: "-5px",
    borderRadius: 3,
    overflow: "visible",
    minHeight: "auto",
  },
  scroller: {
    overflow: "visible !important",
  },
  indicator: {
    backgroundColor: "transparent",
  },
})(Tabs);
export const CustomTab = withStyles((theme) => ({
  root: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightRegular,
    minHeight: 40,
    "&.Mui-selected": {
      color: "white",
      fontWeight: theme.typography.fontWeightMedium,
      backgroundColor: "#FE138A",
      transform: "scale(1.01, 1.2)",
      borderRadius: 5,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

export const ColorButton = withStyles({
  root: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    color: "#3c4049",

    backgroundColor: "#fff",
    fontSize: (props) => (props.size === "small" ? 12 : props.fontSize || 15),
    padding: (props) => (props.size === "small" ? 4 : 7),
    textTransform: "capitalize",
    borderBottomLeftRadius: (props) => (props.displaymenu ? "0" : ""),
    borderBottomRightRadius: (props) => (props.displaymenu ? "0" : ""),
    borderBottom: (props) =>
      props.displaymenu ? "1px solid rgba(0, 0, 0, 0.2)" : "",
    cursor: (props) => (props.displaymenu ? "default" : "pointer"),
    "&:hover": {
      backgroundColor: (props) => (props.displaymenu ? "#fff" : ""),
    },
    "& .item-label": {
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  },
})(Button);

export const ApproveButton = withStyles({
  root: {
    color: "white",
    backgroundColor: (props) =>
      props.approve ? "#28b147" : props.reject ? "#f63109" : "gray",
    fontSize: 10,
    padding: "13px 30px",
    textTransform: "capitalize",
  },
  label: {
    fontWeight: 300,
  },
})(Button);

export const ReviewLoanButtonWrapper = withStyles({
  width: "100%",
  height: 48,
  lineHeight: 48,
  padding: "0 16",
  minWidth: 48,
  minHeight: "auto",
  backgroundColor: "#ff9c0d",
  borderRadius: 24,
  textAlign: "center",
})(Button);

export const UploadFileButton = withStyles({
  root: {
    color: "white",
    backgroundColor: "#1665D8",
    fontSize: 14,
    padding: "9px 16px",
    textTransform: "capitalize",
  },
})(Button);

const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "#2196f3";
  }
  return "#eeeeee";
};
export const UploadWidget = styled.div`
  flex: 1;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: ${(props) => (props.attachment ? 0.4 : 1.5)}rem;
  margin: ${(props) => (props.attachment ? 0 : "1rem 0")};
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => (props.dropzone ? getColor(props) : "#EAEDF3")};
  border-style: ${(props) => (props.dropzone ? "dashed" : "solid")};
  background-color: ${(props) => (props.dropzone ? "white" : "#FBFBFD")};
  color: #717171;
  ${(props) => props.dropzone && "cursor: pointer;"}
  outline: none;
  transition: border 0.24s ease-in-out;
`;

export const SearchInputWrapper = withStyles({
  root: {
    padding: "5px",
    display: "flex",
    alignItems: "center",
    // boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)'
  },
})(Paper);

export const LandingBody = withStyles({
  root: {
    position: "absolute",
    width: "100%",
    maxWidth: "none",
    minHeight: "100%",
    background: "#fff",
    paddingTop: `${DEVELOPMENTS_TOOLBAR_HEIGHT}`,
    paddingLeft: (props) => (props.menuopen ? "275px" : "76px"),
    paddingRight: 0,
    color: "#3e3f42",
    transition: `padding ${MENU_ANIMATION_DURATION}`,
  },
})(Container);

export const MainPageContainer = styled.div`
  padding: 0;
`;

export const LocationImg = styled.img`
  width: ${(props) => props.size || 80}px;
  height: ${(props) => props.size || 80}px;
`;

// Loans Upload bulk Floating Action Button
export const FabBulkUpload = withStyles({
  root: {
    fontSize: 13,
    textTransform: "capitalize",
    background: "#28b147",
    color: "white",
    marginLeft: "8px",
  },
})(Fab);

export const DropDownList = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    boxShadow:
      "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    zIndex: "10",
    position: "absolute",
    width: "100%",
    maxHeight: 400,
    overflowY: "auto",
    fontSize: (props) => (props.size === "small" ? 13 : 15),
  },
}))(List);

export const LoadingBar = withStyles({
  root: {
    position: "absolute",
    top: (props) => props.top || "50%",
    left: (props) => props.left || "50%",
    zIndex: 1000,
  },
})(CircularProgress);

// export const CustomTextField = withStyles({
//   root: {
//     borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
//     '& label': {
//       '&.Mui-focused': {
//         color: 'rgba(0, 0, 0, 0.54)'
//       }
//     },
//     '& div': {
//       cursor: 'default'
//     },
//     '& input': {
//       cursor: 'default'
//     }
//   }
// })(TextField)
export const CustomTextField = withStyles({
  root: {
    width: (props) => props.width || "100%",
    marginTop: (props) => props.margintop || 0,
    "& .MuiOutlinedInput-input": {
      fontFamily: "Lato,  Century Gothic, Helvetica, Arial",
      fontSize: 14,
      padding: "10.5px 14px",
    },
    "& .MuiOutlinedInput-multiline": {
      padding: 0,
    },
  },
})(TextField);

export const CustomAvatar = withStyles({
  root: {
    width: (props) => props.size || 40,
    height: (props) => props.size || 40,
  },
})(Avatar);

const CompletedProgressStyles = {
  root: {
    width: 60,
  },
  trail: {
    stroke: "#EDECFE",
  },
  path: {
    stroke: "#A3A1FB",
  },
  text: {
    fill: "#000",
    fontSize: "30px",
  },
};
export const CompletedProgress = (props) => {
  const { progress } = props;
  return (
    <CircularProgressbar
      value={progress}
      text={`${progress}%`}
      styles={CompletedProgressStyles}></CircularProgressbar>
  );
};

export const ColorCheckbox = withStyles({
  root: {
    padding: 2,
    "&$checked": {
      color: "#00D983",
    },
  },
  checked: {},
})(Checkbox);

export const CartWrapper = withStyles({
  root: {
    // position:'absolute',
    // right:0,
    // top:0,
    width: (props) => (props.isshow ? "100%" : "0%"),
    transition: (props) =>
      props.isshow ? "width 1s ease-in-out" : "width 1s ease-in-out",
  },
})(Box);
// export const ContractorReview = ({ review: { name, star, review } }) =>
//   <Box mt={2} display="flex">
//     <CustomAvatar
//       alt={name}
//       size={50}
//       src={`https://ui-avatars.com/api/?name=${name}&size=${200}`}
//     />
//     <Box ml={2} width={1} display="flex" flexDirection="column" justifyContent="center">
//       <Box fontSize={14} fontWeight={500} display="flex" justifyContent="space-between">
//         {name} ({star} <FormattedMessage id="stars">{txt => txt}</FormattedMessage>)
//       </Box>
//       <Box my={1} fontSize={14} fontWeight={300} lineHeight={1.5}>
//         {review}
//       </Box>
//     </Box>
//   </Box>
export const Loadscreen = () => {
  return (
    <div className="loadscreen">
      <img src={Loading} alt="loading" />
      <h4>Please wait. Loading your data...</h4>
    </div>
  );
};

export const ContractorReviewBadge = styled.div`
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  font-size: 12px;
  font-weight: 300;
  background-color: #f6ab2e;
  color: white;
  border-radius: 3px;
`;
export const ContractorStatusBadge = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: #3bad4b;
`;

export const ContractorNCABadge = styled.div`
  width: fit-content;
  padding: 0 13px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 11px;
  font-weight: 300;
  background-color: #fe138a;
  color: white;
  border-radius: 15px;
`;

export const AssetsWrapper = styled.div`
  position: relative;
  display: block;
  overflow-x: auto;
  margin-top: 16px;
  white-space: nowrap;
`;

export const AssetWidget = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 80px;
  margin-right: 20px;
  white-space: initial;
  img {
    width: 100%;
    height: 80px;
    border-radius: 3px;
    object-fit: ${(props) => (props.pdf ? "contain" : "cover")};
    object-position: left;
  }
`;

export const SetupPageWrapper = styled.div`
  background-color: #fbfbfd;
  width: 100%;
  height: calc(100vh - ${DEVELOPMENTS_TOOLBAR_HEIGHT});
  color: #0d2744;
  box-sizing: border-box;
  padding: 10px;
  padding-bottom: 100px;
  overflow-y: auto;
`;
export const SetupHeaderTypography = withStyles({
  body1: {
    fontFamily: "Century Gothic",
    fontSize: 32,
    fontWeight: "bold",
    letterSpacing: 0,
  },
})(Typography);

export const SetupSectionTypography = withStyles({
  body1: {
    fontFamily: "Century Gothic",
    fontSize: 18,
    fontWeight: "bold",
    marginTop: 36,
    letterSpacing: 0,
    lineHeight: 1.1,
  },
})(Typography);

export const SetupSubsectionTypography = withStyles({
  body1: {
    fontFamily: "Century Gothic",
    fontSize: "18px",
    letterSpacing: 0,
    lineHeight: 1.1,
  },
})(Typography);

export const Wrapper = styled.div`
  &:before {
    content: " ";
    background-image: linear-gradient(
        to top,
        rgba(143, 118, 42, 0.5) 0%,
        rgba(0, 0, 0, 0.87)
      ),
      url(${srcBgMain});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;
    min-height: 100%;
    min-width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
`;
export const LogoWrapper = styled.div`
  position: relative;
  top: 100px;
  img {
    margin-left: 40px;
  }
`;
export const FormWrapper = withStyles(() => ({
  root: {
    maxWidth: "550px",
    paddingTop: "10px",
    paddingBottom: "20px",
    backgroundColor: "#fff",
    position: "relative",
    top: "100px",
    marginBottom: "20px",
  },
}))(Container);

export const FormTitle = styled.div`
  font-size: 36px;
  text-align: center;
  color: #fe138a;
  letter-spacing: 2px;
`;
export const FormDescription = styled.div`
  font-size: 314x;
  text-align: center;
  color: #9ea0a5;
`;
export const InvalidFeedback = styled.p`
  color: #dd4b39;
`;
export function UploadProgressBar({ progress }) {
  return (
    <Box
      width={1}
      height={5}
      borderRadius={3}
      style={{ backgroundColor: "#EAECEE" }}>
      <Box
        width={progress}
        height={1}
        borderRadius={3}
        style={{ backgroundColor: "#1665D8" }}></Box>
    </Box>
  );
}
