export const userLogin = (data) => ({
  type: "LOGIN_REQUEST",
  payload: data,
});
export const userSignup = (data) => ({
  type: "USER_SIGN_UP",
  payload: data,
});

export const userLoginFire = (data) => ({
  type: "LOGIN_FIREBASE_REQUEST",
  payload: data,
});

export const getFirebaseToken = (data) => ({
  type: "GET_FIREBASE_TOKEN",
  payload: data,
});

export const userLogout = () => ({
  type: "LOGOUT_REQUEST",
});
export const setPhoneNumber = (payload) => ({
  type: "SET_PHONE",
  payload,
});
export const setUserDetails = (payload) => ({
  type: "SET_PERSONAL_DETAILS",
  payload,
});
export const updateChatbotMessages = (payload) => ({
  type: "UPDATE_CHATBOT",
  payload,
});
export const updateMyMessages = (payload) => ({
  type: "UPDATE_CHATME",
  payload,
});
