/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import "react-chat-elements/dist/main.css";
import { TextField, InputAdornment } from "@material-ui/core";
import girl from "../assets/AYZH/ayzh_model3.png";
import girl1 from "../assets/AYZH/ayzh_model.png";
import sendButton from "../assets/AYZH/plane (2).svg";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import Avatar from "react-avatar";
import {
  updateChatbotMessages,
  updateMyMessages,
} from "../actions/authActions";
import ChatOptionDropdown from "./ChatOptionDropdown";

export const ConfidenceChat = ({ instance }) => {
  useEffect(() => {
    const element = document.querySelector(".messeges-body");

    window.watsonAssistantChatOptions = {
      integrationID: "0c2eea04-30c2-4f98-a531-c3cb32eaf313", // The ID of this integration.
      region: "au-syd", // The region your integration is hosted in.
      serviceInstanceID: "8527680b-2000-440a-bbb5-0f0622217925", // The ID of your service instance.
      element: element,
      hideCloseButton: true,
      showLauncher: true,
      openChatByDefault: true,
      // The callback function that is called after the widget instance has been created.
      onLoad: function (instance) {
        instance.render();
      },
    };

    setTimeout(function () {
      const t = document.createElement("script");
      t.src =
        "https://web-chat.global.assistant.watson.appdomain.cloud/loadWatsonAssistantChat.js";
      document.head.appendChild(t);
    });
  });

  return (
    <div className="projects">
      <div className="messeges-body">
        <div className="ayzh-girl">
          <img src={girl1} style={{ height: "90%" }} alt="girl" />
        </div>
      </div>
      <div className="message-input"></div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.authReducer.activeUser,
  messos: state.authReducer.messos,
  mymessos: state.authReducer.mymessos,
});

const mapDispatchToProps = (dispatch) => ({
  updateChatbotMessages: (data) => dispatch(updateChatbotMessages(data)),
  updateMyMessages: (data) => dispatch(updateMyMessages(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfidenceChat);
