/* eslint-disable no-unused-vars */
import React, { Component,Fragment,useState } from 'react'
import { connect } from 'react-redux'
import { CustomTabs, CustomTab } from './components'
import { MainPageContainer } from '@/components/general'
import Materials from './indexr' 

export const MaterialTabs = () => {
    const [ActiveTab,setActiveTab]= useState(0)
    const handleChangeTab = (event, newValue) => {
        setActiveTab(newValue)
      }
    return (
<MainPageContainer>
      {/* <Fragment>
        <h1>Your Material Suppliers</h1> */}
      {/* <Box m={0} display="flex">
          <Box width={1 / 2} mr={1}>
            <Card>
              <Box p={2} textAlign="right">
                <SquareButton>Browse Suppliers</SquareButton>
                <Box mb={2} display="flex" flexWrap="wrap">
                  {Services.map((service, index) =>
                    <ServiceButton key={index}>
                      <NavigationIcon />
                      {service}
                    </ServiceButton>)}
                </Box>

                <CustomTabs value={activeTab} onChange={handleChangeTab}>
                  <CustomTab label="Favorities" />
                  <CustomTab label="Recent" />
                  <CustomTab label="Active" />
                </CustomTabs>

              </Box>

              <SearchWrapper>
                <SerchInput
                  fullWidth
                  value={search}
                  placeholder="Search Suppliers"
                  onChange={handleChangeSearch}
                />
                <IconButton aria-label="SearchIcon">
                  <SearchIcon fontSize="small" />
                </IconButton>
              </SearchWrapper>
              <Divider />
              <List>
                {filteredContractors && filteredContractors.map((supplier, idx) =>
                  <Fragment key={idx}>
                    <Supplier supplier={supplier} />
                    <Divider />
                  </Fragment>)}
              </List>
            </Card>
          </Box>
          <Box width={1 / 2} ml={1}>
            <Route path="/developments/material-orders/:id?" component={Detail} />
          </Box>

        </Box> */}
      <Materials/>
      {/* </Fragment> */}
    </MainPageContainer>
    )
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(MaterialTabs)
