/* eslint-disable no-unused-vars */

import React, { Fragment, useState } from 'react'
import {Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'

import {
  Box,
  Card,
  List,
  Divider,
  IconButton
} from '@material-ui/core'
import {
  Navigation as NavigationIcon,
  Search as SearchIcon
} from '@material-ui/icons'


import {
  SquareButton,
  ServiceButton,
  CustomTabs,
  CustomTab,
  SearchWrapper,
  SerchInput
} from './components'

import Supplier from './supplier'

import MaterialTabs from './MaterialTabs'
// import Hardware from './hardware'

const Services = ['Plumbing', 'Electrical', 'Welding', 'Steel Work', 'Painting', 'Tile Work', 'Carpentry', 'Masonry']

const MaterialOrdersPage = ({ contractors }) => {

  const [activeTab, setActiveTab] = useState(0)
  const [search, setSearch] = useState('')
  const [filteredContractors, setFilteredContractors] = useState(contractors)

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue)
  }

  const handleChangeSearch = ({ target }) => {
    setSearch(target.value)
    const strSearch = target.value.toLowerCase()
    // TODO There should be search index in contractor modal when api is ready
    setFilteredContractors(contractors.filter(contractor => contractor.name.toLowerCase().includes(strSearch)))
  }

  return (
    <div className='projects'>
      {/* <Fragment>
        <h1>Your Material Suppliers</h1> */}
      {/* <Box m={0} display="flex">
          <Box width={1 / 2} mr={1}>
            <Card>
              <Box p={2} textAlign="right">
                <SquareButton>Browse Suppliers</SquareButton>
                <Box mb={2} display="flex" flexWrap="wrap">
                  {Services.map((service, index) =>
                    <ServiceButton key={index}>
                      <NavigationIcon />
                      {service}
                    </ServiceButton>)}
                </Box>

                <CustomTabs value={activeTab} onChange={handleChangeTab}>
                  <CustomTab label="Favorities" />
                  <CustomTab label="Recent" />
                  <CustomTab label="Active" />
                </CustomTabs>

              </Box>

              <SearchWrapper>
                <SerchInput
                  fullWidth
                  value={search}
                  placeholder="Search Suppliers"
                  onChange={handleChangeSearch}
                />
                <IconButton aria-label="SearchIcon">
                  <SearchIcon fontSize="small" />
                </IconButton>
              </SearchWrapper>
              <Divider />
              <List>
                {filteredContractors && filteredContractors.map((supplier, idx) =>
                  <Fragment key={idx}>
                    <Supplier supplier={supplier} />
                    <Divider />
                  </Fragment>)}
              </List>
            </Card>
          </Box>
          <Box width={1 / 2} ml={1}>
            <Route path="/developments/material-orders/:id?" component={Detail} />
          </Box>

        </Box> */}
        <Switch> 
        <Route exact path='/confidence/services' component={MaterialTabs} />
        {/* <Route exact path='/supplier/orders/hardware/:hardwareID' component={Hardware} /> */}
        </Switch>
      {/* <Materials/> */}
      {/* </Fragment> */}
    </div>
  )
}

const mapStateToProps = state => ({
  contractors: state.authReducer.activeUser
})

export default connect(
  mapStateToProps
)(MaterialOrdersPage)
