/* eslint-disable no-unused-vars */
import axios from "axios";
const REG_ABSOLUTE_URL = /^(?:\w+:)\/\//;

// axios.defaults.withCredentials = true

const apiRequest = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINT,
  withCredentials: true,
});

apiRequest.interceptors.request.use((config) => {
  if (!config.headers["Content-Type"]) {
    config.headers["Content-Type"] = "application/json;charset=utf-8";
  }

  const baseURL = localStorage.getItem("iBG_Endpoint");
  const token = localStorage.getItem("iBG_Token");
  //   const businessId = localStorage.getItem('iBG_BusinessID')

  //   const defaultBusinessId = localStorage.getItem('iBG_DefaultBusinessID')
  const xDebugSessionStart = localStorage.getItem("iBG_XDdebugSessionStart");

  if (!!baseURL && !REG_ABSOLUTE_URL.test(config.url)) {
    config.url = `${baseURL}${config.url}`;
  }

  if (!!xDebugSessionStart && xDebugSessionStart !== "null") {
    config.params = { XDEBUG_SESSION_START: xDebugSessionStart };
  }

  if (!!token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    return config;
  }

  //   if (!!businessId) {
  //     config.headers.business = businessId
  //   } else if (!!defaultBusinessId) {
  //     config.headers.business = defaultBusinessId
  //   }

  return config;
});
const authApi = {
  userSignup: async (input) => {
    const {
      data: { user, message, errors, accessToken },
    } = await apiRequest.post("api/register", input);
    if (errors || !user) {
      return {
        success: false,
        error: message,
      };
    }

    console.log(user);
    return {
      success: true,
      data: {
        user: user,
        token: accessToken,
      },
    };
  },

  userLogin: async (input) => {
    const payload = {
      username: input.username,
      password: input.password,
    };

    // const stuff = await apiRequest.get('sanctum/csrf-cookie').then(async response => {

    const {
      data: { user, message, errors, access_token },
    } = await apiRequest.post("api/session-login", payload);
    if (errors || !user) {
      console.log(Object.values(errors).toString());
      return {
        success: false,
        error: message,
      };
    }

    console.log(user);
    return {
      success: true,
      data: {
        user: user,
        token: access_token,
      },
    };
    // })

    // return stuff
  },
};
export { authApi };
