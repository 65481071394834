/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { connect } from "react-redux";
import {
  TextField,
  InputAdornment,
  InputLabel,
  input,
  FormControl,
  Checkbox,
} from "@material-ui/core";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import logo from "../assets/AYZH/logo.png";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import VisibilityIcon from "@material-ui/icons/Visibility";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import LockIcon from "@material-ui/icons/Lock";
import { VisibilityOff, Visibility } from "@material-ui/icons";

import { userSignup } from "@/actions";
import { toast } from "react-toastify";

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "#F4F4F4",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#F4F4F4",
      },
    },
  },
})(TextField);

export const Signup = ({ history, userSignup, phone_number }) => {
  const [checked, setChecked] = React.useState(false);
  const [validationErrors, setErrors] = useState({
    last_name: "",
    first_name: "",
    password: "",
    passwordConfirm: "",
  });

  const [userdetails, setUserDetails] = useState({
    first_name: "",
    last_name: "",
    date_of_birth: "2017-05-24",
    password: "",
    password_confirm: "",
  });

  const [validation, setValidation] = useState(true);
  const [validationClear, setValidationClear] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const textChange = (value, name) => {
    let current_detail = { ...userdetails };
    current_detail[name] = value;
    setUserDetails(current_detail);
  };

  const checkValidation = () => {
    let { first_name, last_name, date_of_birth, password, password_confirm } =
      userdetails;
    let validation_list = { ...validationErrors };
    for (const [key, value] of Object.entries(userdetails)) {
      validation_list[key] = value === "" ? "Please fill in this Field" : "";
    }

    setErrors(validation_list);
    if (Object.values(userdetails).includes("")) {
      for (const [key, value] of Object.entries(userdetails)) {
        validation_list[key] = value === "" ? "Please fill in this Field" : "";
      }
      setValidation(true);
      setErrors(validation_list);
    } else if (password.length < 4) {
      validation_list.password = "PIN must be atleast 4 characters!";
      setValidation(true);
      setErrors(validation_list);
    } else if (password_confirm !== password) {
      validation_list.passwordConfirm = "PIN Mismatch !";
      setValidation(true);
      setErrors(validation_list);
    } else if (!checked) {
      toast.error("Please check if you agree to the Terms and conditions");
    } else {
      let data = {
        first_name,
        last_name,
        date_of_birth,
        country_id: 1,
        phone_number,
        password,
        password_confirm,
      };
      userSignup(data);
    }
  };
  const handleCheckChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <div className="signIn_details">
      <div className="white">
        <h1>CHAT WITH CONFIDENCE</h1>
      </div>
      <div className="sign-up-card">
        <h1>Please provide your details to sign up</h1>
        <div className="name-form">
          <TextField
            label="First name"
            className="username"
            name="username"
            error={validation && validationErrors.first_name !== ""}
            helperText={validationErrors.first_name}
            type="text"
            size="small"
            autoComplete="new-password"
            margin="normal"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonOutlineIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => textChange(e.target.value, "first_name")}
          />
          <TextField
            label="Last name"
            className="username"
            name="username"
            error={validation && validationErrors.last_name !== ""}
            helperText={validationErrors.last_name}
            autoComplete="new-password"
            type="text"
            size="small"
            onChange={(e) => textChange(e.target.value, "last_name")}
            margin="normal"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonOutlineIcon />
                </InputAdornment>
              ),
              autoComplete: "new-password",
              form: {
                autoComplete: "off",
              },
            }}
          />
          <TextField
            id="date"
            label="Birth Year & Month"
            type="date"
            defaultValue="2017-05-24"
            onChange={(e) => textChange(e.target.value, "date_of_birth")}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <hr />
        <div className="password-form">
          <TextField
            label="Enter your 4 digit PIN here"
            className="username"
            name="username"
            error={validation && validationErrors.password !== ""}
            helperText={validationErrors.password}
            type={showPassword ? "text" : "password"}
            size="small"
            autoComplete="off"
            onInput={(e) => {
              if (/^[1-9]\d*(\d+)?$/i.test(e.target.value)) {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 4);
              } else {
                e.target.value = "";
              }
            }}
            margin="normal"
            InputProps={{
              inputmode: "numeric",
              pattern: "[0-9]*",
              startAdornment: (
                <InputAdornment position="start">
                  <LockOpenIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  position="end">
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </InputAdornment>
              ),
              autoComplete: "new-password",
              form: {
                autoComplete: "off",
              },
            }}
            onChange={(e) => textChange(e.target.value, "password")}
          />
          <TextField
            label="Confirm PIN"
            className="username"
            name="username"
            error={validation && validationErrors.passwordConfirm !== ""}
            helperText={validationErrors.passwordConfirm}
            type={showConfirmPassword ? "text" : "password"}
            size="small"
            autoComplete="off"
            onInput={(e) => {
              if (/^[1-9]\d*(\d+)?$/i.test(e.target.value)) {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 4);
              } else {
                e.target.value = "";
              }
            }}
            margin="normal"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOpenIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  onClick={handleClickShowConfirmPassword}
                  onMouseDown={handleMouseDownConfirmPassword}
                  position="end">
                  {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                </InputAdornment>
              ),
            }}
            onChange={(e) => textChange(e.target.value, "password_confirm")}
          />
        </div>
        <div className="terms-and-conditions">
          <Checkbox
            inputProps={{ "aria-label": "primary-checkbox" }}
            checked={checked}
            onChange={handleCheckChange}
          />
          I agree with the Go with Confidence Terms and Condition and the
          Privacy Policy.
        </div>
        <div
          className="signin-signup"
          style={{ justifyContent: "center" }}
          onClick={() => checkValidation()}>
          <span>SUBMIT</span>
        </div>
      </div>
      <div className="scarlet">
        <img src={logo} alt="logo"></img>
      </div>
      <div className="orange"></div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  phone_number: state.authReducer.phone_number,
});
const mapDispatchToProps = (dispatch) => ({
  userSignup: (data) => dispatch(userSignup(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
