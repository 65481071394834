import styled from 'styled-components'
import {
  withStyles,
  Button,
  Fab,
  Tabs,
  Tab,
  InputBase,
  Box
} from '@material-ui/core'

export const SquareButton = withStyles({
  root: {
    color: 'white',
    backgroundColor: '#3399ff',
    fontSize: 12,
    fontWeight: 300,
    padding: '7px 10px',
    textTransform: 'capitalize'
  }
})(Button)
export const ServiceButton = withStyles({
  root: {
    width: 120,
    height: 35,
    color: 'white',
    backgroundColor: props => props.active ? '#3399ff' : '#9ea0a5',
    fontSize: 10,
    fontWeight: 300,
    margin: 5,
    textTransform: 'capitalize',
    borderRadius: 24
  },
  label: {
    justifyContent: 'left',
    paddingLeft: 15
  }
})(Fab)

export const CustomTabs = withStyles({
  root: {
    minHeight: 'auto',
    border:'1px gainsboro solid'
  },
  indicator: {
    height: 3,
    backgroundColor: '#1665d8'
  }
})(Tabs)
export const CustomTab = withStyles({
  root: {
    fontSize: '1em',
    fontWeight: 400,
    color: '#6b6c6f',
    textTransform: 'none',
    width:300,
    minWidth: 150,
    minHeight: 'auto',
    '&.Mui-selected': {
      color: '#3e3f42',
      fontWeight: 500
    }
  }
})(Tab)

export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  line-height: 48px;
  padding: 0 15px;
`
export const SerchInput = withStyles({
  input: {
    fontSize: 12
  }
})(InputBase)

export const ReviewBadge = styled.div`
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  font-size: 12px;
  font-weight: 300;
  background-color: #f6ab2e;
  color: white;
  border-radius: 3px;
`
export const StatusBadge = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: #3bad4b;
`

export const NCABadge = styled.div`
  width: fit-content;
  padding: 0 10px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  font-size: 10px;
  font-weight: 300;
  background-color: #FE138A;
  color: white;
  border-radius: 13px;
  letter-spacing: -0.5px;
`
export const AssetWidget = styled.div`
  width: 80px;
  margin-right: 20px;
  img {
    width: 100%;
    height: 80px;
    border-radius: 3px;
    object-fit: cover;
  }
`
export const NoItems = styled.div`
padding: 3rem;
text-align: center;
font-size: 20px;
`
export const DetailWrapper = withStyles({
  root: {
    width: props => props.isshow ? '100%' : '0%',
    transition: props => props.isshow ? 'width 1s ease-in-out' : 'none'
  }
})(Box)