/* eslint-disable no-unused-vars */
import React, { Component } from 'react'
import {
  Navigation as NavigationIcon,
  Search as SearchIcon
} from '@material-ui/icons'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import _ from 'lodash'

export class SearchComponent extends Component {
  state = {
    hardwares:[],
    value:''
  }
  componentDidMount() {
    this.fetchUniq()
  }
  componentDidUpdate(prevProps) {
    if (this.props.hardwares !== prevProps.hardwares) {
      // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
      this.fetchUniq()
    }
  }
  fetchUniq = () => {
    const { hardwares, type } = this.props
    let suppliers =   _.uniqBy(hardwares, type)
    this.setState({
      hardwares:suppliers
    })
  }
  filter = (e, value) => {
    const { type } = this.props
    this.setState({
      value
    }, () => this.props.filter(type, value))
  }
  render() {
    const { type, label } = this.props
    const { hardwares, value } = this.state
    return (
      <div className="searchBox">
        <Autocomplete
          id={type}
          options={hardwares}
          getOptionLabel={(option) => option[type]}
          onChange={this.filter}
          style={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label={label} margin="normal" />}
        />
      </div>
    )
  }
}

export default SearchComponent
