import React, { Component } from "react";
import flags from "@/assets/svgs/maps-and-flags (1).svg";
import house from "@/assets/svgs/home.svg";

export class HomeImage extends Component {
  render() {
    return (
      <div className="home-img">
        {/* <div className="marker-info-box">
          <div className="arrow-box"></div>
        </div> */}
        <img className="first-box" src={flags} alt="flags" />
        <img src={house} alt="home" className="inner-box" />
      </div>
    );
  }
}

export default HomeImage;
